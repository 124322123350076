export const TOKEN_KEY = "front-manager-center";
export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const getUser = () => sessionStorage.getItem('user');
export const getPublicKey = () => sessionStorage.getItem('publicKey');
export const getProfile = () => sessionStorage.getItem('profile');
export const getPermissions = () => localStorage.getItem('permissions');

export const login = token => {
    sessionStorage.setItem(TOKEN_KEY, token);
};

export const setUser = user => {
    sessionStorage.setItem('user', user);
};

export const setPublicKey = publicKey => {
    sessionStorage.setItem('publicKey', publicKey);
};

export const setProfile = profile => {
    sessionStorage.setItem('profile', profile);
};

export const setPermissions = permissions => {
    localStorage.setItem('permissions', permissions);
};

export const logout = () => {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('profile');
    localStorage.removeItem('permissions');
};
