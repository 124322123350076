import { getPermissions } from '../services/auth';

const useGetUserPermissions = () => {
  const permissionsByUser = getPermissions();
  
  return JSON.parse(permissionsByUser);
}

const PermissionGate = ({ children, module, permissions }) => {
  const userPermissions = useGetUserPermissions()
  let result = null;

  userPermissions.forEach(element => {
    if (element.description === module) {
      const act = element.actions;
      if (Object.keys(act).find(key => act[key] === permissions)) {
        result = children;
      }
    }
  });

  return result
}

export default PermissionGate
