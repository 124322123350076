import React, { useState} from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import Loader from "../../components/Loader";

export default function RequestTransfer() {

  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (firstDate === null || firstDate === "") {

        setLoading(false);
        setError("Data inicial é obrigatória");
        return;
    }

    if (lastDate === null || lastDate === "") {

        setLoading(false);
        setError("Data final é obrigatória");
        return;
    }

    try {

        const headers = {
            action: "RequestTransferManual:view",
          };
        const response = await api.post("/transfer/request", {
          startDate: firstDate,
          endDate: lastDate
        },
        {
            headers
        });

        const count = response.data.length;
        setError("");
        setAlert(count + " Pagamento(s) atualizado(s)");
        setLoading(false);

    } catch (err) {

        setAlert("");
        setLoading(false);
        setError("Nenhuma transferencia encontrada");
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            
            { loading ? (
                  <Loader />
                ) : (
                <div className="col-md-12 col-lg-12 text-center">
                { error && (
                    <div className="alert alert-danger" role="alert">
                    {error}
                    </div>
                )}
              
                { alert && (
                    <div className="alert alert-success" role="alert">
                    {alert}
                    </div>
                )}
              </div>
            )}
            
            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Carregar retorno bancário
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                    
                  <div className="col-md-12">
                      <label className="form-label"><b>Período:</b></label>
                      <input
                        type="date"
                        autoFocus="focus"
                        className="form-control"
                        value={firstDate}
                        onChange={(event) => setFirstDate(event.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label"><b>Até:</b></label>
                      <input
                        type="date"
                        className="form-control"
                        value={lastDate}
                        onChange={(event) => setLastDate(event.target.value)}
                      />
                    </div>
                    <div className="d-grid gap-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "5%" }}
                      >
                        Carregar
                      </button>
                    </div>
                </form>
              </div>
            </div>
            
            <br></br>
          </div>
        </div>
      </div>
    </>
  );

};
