import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import Loader from "../../components/Loader";
import { MoneyEUAtoBR } from "../../components/MoneyEUAtoBR";

export default function BalanceMajorCompany() {
  const [error, setError] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState("");
  const [countInvoices, setCountInvoices] = useState(0);
  const [sumInvoices, setSumInvoices] = useState(0);
  const [lucrativity, setLucrativity] = useState([]);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    if (!year || year === "") {
      setLoading(false);
      setError("Campo ano é obrigatório");
      return;
    }

    if (!month || month === "") {
        setLoading(false);
        setError("Campo mês é obrigatório");
        return;
      }

    try {
      const headers = {
        action: "BalanceMajorCompany:view",
      };
      const response = await api.post(
        "/platform_lucrativity_by_month",
        {
          year,
          month
        },
        {
          headers,
        }
      );

      setError("");
      setLoading(false);
      setLucrativity(response.data.lucrativity);
      loadInvoices(response.data.invoicesPaid);

    } catch (err) {
      setLoading(false);
      setError("Dados não encontrados");
    }
  }

  const loadInvoices = (invoices) => {

    const countAllInvoices = invoices.length;
    let sumAllInvoices = 0;
    invoices.map((invoice) =>
        sumAllInvoices += invoice.amount_paid,
    );

    setCountInvoices(countAllInvoices);
    setSumInvoices(sumAllInvoices);

  }

  const loadTotalComission = () => {
    
    let totalComission = 0;

    lucrativity.map((lucr) => 
        totalComission = lucr.sumInvoices + lucr.sumWithdrawTaxes + lucr.sumVirtualTransReceived + lucr.sumfinancialReturn + sumInvoices - (lucr.bankTax + lucr.sumVirtualTransSender)
    )

    return totalComission;
  }


  const loadTotalPlatform = () => {
    
    let totalComission = 0;

    lucrativity.map((lucr) => 
        totalComission = lucr.sumInvoices + lucr.sumWithdrawTaxes + lucr.sumVirtualTransReceived - (lucr.bankTax + lucr.sumVirtualTransSender)
    )

    return totalComission;
  }
  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-lg-8 offset-md-2">
            <div className="col-md-12 col-lg-12 text-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
            </div>

            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Pesquisar Balanço financeiro Reobote
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label">Ano Base:</label>
                      <input
                        type="text"
                        autoFocus="focus"
                        className="form-control"
                        maxLength={4}
                        placeholder="Ex: 2021"
                        value={year}
                        onChange={(event) => setYear(event.target.value)}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">Mês Base:</label>
                        <select
                            className="form-control"
                            value={month}
                            onChange={(event) => setMonth(event.target.value)}
                        >
                            <option value="1"> Janeiro</option>
                            <option value="2"> Fevereiro</option>
                            <option value="3"> Março</option>
                            <option value="4"> Abril</option>
                            <option value="5"> Maio</option>
                            <option value="6"> Junho</option>
                            <option value="7"> Julho</option>
                            <option value="8"> Agosto</option>
                            <option value="9"> Setembro</option>
                            <option value="10"> Outubro</option>
                            <option value="11"> Novembro</option>
                            <option value="12"> Dezembro</option>
                        </select>
                      
                    </div>
                    <div className="col-md-3">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "20%" }}
                      >
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card">
              <div className="card-header text-white bg-dark">
                Extrato financeiro
              </div>
              <div className="card-body"> 

               { loading ? (
                  <Loader />
                ) : (

                <div className="row">
                  {lucrativity.map((lucr) =>
                    
                      <div className="col-md-12 col-lg-12" style={{fontSize: "14px"}}>
                        <ul key={lucr.month} className="list-group">
                          <li className="list-group-item list-group-item-secondary">
                            <strong>Mês: {lucr.month}</strong>
                          </li>

                          <li className="list-group-item">
                            <strong>Movimentação Plaforma</strong>
                          </li>

                          <li className="list-group-item d-flex w-100 justify-content-between">
                            Total de boletos pagos: <span>{lucr.countBankSlip}</span>
                          </li>

                          <li className="list-group-item d-flex w-100 justify-content-between">
                          Valor total de lucro sobre boletos baixados: <span>{MoneyEUAtoBR(lucr.sumBankSlip / 100)}</span>
                          </li>

                          <li className="list-group-item d-flex w-100 justify-content-between">
                            Total de PIX realizados: <span>{lucr.countPix}</span>
                          </li>

                          <li className="list-group-item d-flex w-100 justify-content-between">
                          Valor total de lucro sobre PIX realizados: <span>{MoneyEUAtoBR(lucr.sumPix / 100)}</span>
                          </li>

                          <li className="list-group-item list-group-item-warning d-flex w-100 justify-content-between">
                            Total de faturas pagas: <span>{lucr.countInvoices}</span>
                          </li>

                          <li className="list-group-item list-group-item-warning d-flex w-100 justify-content-between">
                          Valor total de lucro sobre faturas baixadas: <span>{MoneyEUAtoBR(lucr.sumInvoices / 100)}</span>
                          </li>

                          <li className="list-group-item list-group-item-info d-flex w-100 justify-content-between">
                            Total transf bancárias realizadas: <span>{lucr.countWithdrawTaxes}</span>
                          </li>

                          <li className="list-group-item list-group-item-info d-flex w-100 justify-content-between">
                          Valor total de lucro sobre transf bancárias realizadas: <span>{MoneyEUAtoBR(lucr.sumWithdrawTaxes / 100)}</span>
                          </li>

                          <li className="list-group-item list-group-item-success d-flex w-100 justify-content-between">
                            Total transferencias virtuais recebidas: <span>{lucr.countVirtualTransferReceived}</span>
                          </li>

                          <li className="list-group-item list-group-item-success d-flex w-100 justify-content-between">
                            Valor total transferencias virtuais recebidas: <span>{MoneyEUAtoBR(lucr.sumVirtualTransReceived/100)}</span>
                          </li>

                          <li className="list-group-item list-group-item d-flex w-100 justify-content-between">
                            Total transferencias virtuais enviadas: <span>{lucr.countVirtualTransferSender}</span>
                          </li>

                          <li className="list-group-item list-group-item d-flex w-100 justify-content-between">
                            Valor total transferencias virtuais enviadas: <span>{MoneyEUAtoBR(lucr.sumVirtualTransSender/100)}</span>
                          </li>

                          <li className="list-group-item list-group-item-danger d-flex w-100 justify-content-between">
                            Total taxa manutenção de conta: <span>1</span>
                          </li>

                          <li className="list-group-item list-group-item-danger d-flex w-100 justify-content-between">
                            Valor total taxa de manutenção de conta: <span>{MoneyEUAtoBR(lucr.bankTax/100)}</span>
                          </li>

                          <li className="list-group-item list-group-item-secondary d-flex w-100 justify-content-between">
                            . <span><b>{MoneyEUAtoBR(loadTotalPlatform()/100)}</b></span>
                          </li>

                          <li className="list-group-item d-flex w-100 justify-content-between">
                            <strong>Faturamento Reobote (Mensalidades e prestação de serviços)</strong>
                          </li>

                          <li className="list-group-item list-group-item-primary d-flex w-100 justify-content-between">
                            Total de boletos recebidos: <span>{countInvoices}</span>
                          </li>

                          <li className="list-group-item list-group-item-primary d-flex w-100 justify-content-between">
                            Valor total de boletos recebidos: <span>{MoneyEUAtoBR(sumInvoices/100)}</span>
                          </li>

                          <li className="list-group-item d-flex w-100 justify-content-between">
                            <strong>Rendimento mensal com investimento de saldo em conta bancária</strong>
                          </li>

                          <li className="list-group-item list-group-item-success d-flex w-100 justify-content-between">
                            Valor total lucro com rendimento: <span>{MoneyEUAtoBR(lucr.sumfinancialReturn/100)}</span>
                          </li>

                          <li className="list-group-item d-flex w-100 justify-content-between">
                            <strong>Total do Faturamento geral (Lucro plataforma + Rendimento + Mensalidades e prestação de serviços)</strong>
                          </li>

                          <li className="list-group-item list-group-item-secondary d-flex w-100 justify-content-between">
                            . <span><b>{MoneyEUAtoBR(loadTotalComission()/100)}</b></span>
                          </li>

        
                        </ul>
                        <br></br>
                      </div>
            
                  )}
                </div>
                ) }
              </div>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
