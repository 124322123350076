import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import Loader from "../../components/Loader";
import {MoneyEUAtoBR} from "../../components/MoneyEUAtoBR";
import {DateEUAtoBR} from "../../components/DateEUAtoBR";

export default function InvoicePaidByDayAndRates() {
  const [error, setError] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!startDate || startDate === "") {
      setLoading(false);
      setError("Campo Data Inicial de pesquisa é obrigatório");
      return;
    }

    if (!endDate || endDate === "") {
        setLoading(false);
        setError("Campo Data Inicial de pesquisa é obrigatório");
        return;
    }

    try {
      const headers = {
        action: "InvoiceRanking:view",
      };
      const response = await api.post(
        "/report/invoices_paid_by_dayandtype",
        {
            startDate,
            endDate
        },
        {
          headers,
        }
      );

      setLoading(false);
      setError("");
      setInvoices(response.data.invoices_list);

    } catch (err) {
      setLoading(false);
      setError("Dados não encontrados");
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="col-md-12 col-lg-12 text-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
            </div>

            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Pesquisar faturas pagas por dia e tipo
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label">Período:</label>
                      <input
                        type="date"
                        autoFocus="focus"
                        className="form-control"
                        value={startDate}
                        onChange={(event) => setStartDate(event.target.value)}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">Até:</label>
                      <input
                        type="date"
                        autoFocus="focus"
                        className="form-control"
                        value={endDate}
                        onChange={(event) => setEndDate(event.target.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "20%" }}
                      >
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            { loading ? (
                  <Loader />
                ) : (
            <div className="card">
              <div className="card-header text-white bg-dark">
                Relação de faturas pagas por dia e tipo
              </div>
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-active">
                      <th scope="col">Data</th>
                      <th scope="col" className="text-center">PIX</th>
                      <th scope="col" className="text-end">PIX Taxas</th>
                      <th scope="col" className="text-end">Total PIX Taxas</th>
                      <th scope="col" className="text-center">Boletos</th>
                      <th scope="col" className="text-end">Boletos Taxas</th>
                      <th scope="col" className="text-end">Total Boletos Taxas</th>
                      <th scope="col" className="text-end" >Total de faturas</th>
                      <th scope="col" className="text-end" >Total geral de taxas</th>
                    </tr>
                  </thead>

                  <tbody style={{ fontSize: "14px" }}>
                    {invoices.map((invoice) => (
                      <tr key={invoice._id}>
                        <td>{DateEUAtoBR(invoice._id).substring(0,11)}</td>
                        <td className="text-center">{invoice.pix}</td>
                        <td className="text-end">{MoneyEUAtoBR(invoice.pix_rate/100)}</td>
                        <td className="text-end">{MoneyEUAtoBR(invoice.total_pix_taxes/100)}</td>
                        <td className="text-center">{invoice.bank_slip}</td>
                        <td className="text-end">{MoneyEUAtoBR(invoice.bank_slip_rate/100)}</td>
                        <td className="text-end">{MoneyEUAtoBR(invoice.total_bank_slip_taxes/100)}</td>
                        <td className="text-end">{invoice.total_invoices}</td>
                        <td className="text-end">{MoneyEUAtoBR(invoice.total_bank_slip_taxes/100+invoice.total_pix_taxes/100)}</td>
                      </tr>
                      
                    ))}
                  </tbody>
              
                </table>
              </div>
            </div>
                ) }
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
