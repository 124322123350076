import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { MoneyEUAtoBR } from "../../components/MoneyEUAtoBR";
import Loader from "../../components/Loader";

export default function SearchPixInformation() {
  const [error, setError] = useState("");
  const [idTransaction, setIdTransaction] = useState("");
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState(null);
  const [listaPix, setListaPix] = useState([], true);
  const [listaDevolucao, setListaDevolucao] = useState([], true);

  const statusDevolution = {
    419: "Liquidação da transação interrompida devido a timeout no SPI",
    420: "Erro na instituição do recebedor",
    421: "Conta do recebedor inexistente ou inválida",
    422: "Conta recebedora está bloqueada",
    423: "Número da conta transacional encerrada no Provedor (PSP) do Recebedor",
    424: "Tipo incorreto para a conta transacional especificada. Conta recebedor inexistente ou inválida",
    425: "Participante direto não é liquidante do participante do usuário pagador",
    426: "Conta recebedora não está autorizada para a operação ",
    427: "Ordem de pagamento com valor igual a zero",
    428: "Pix não efetivado (PI)",
    429: "Valor da devolução é maior que o valor do pagamento",
    430: "Quantidade de transação inválida ",
    431: "CPF/CNPJ do usuário recebedor não é consistente com o titular da conta transacional especificada",
    432: "CPF/CNPJ do usuário recebedor incorreto",
    433: "Preenchimento da mensagem está incorreto ou incompatível",
    434: "Pix não efetivado (PI)",
    435: "Pagamento rejeitado pelo PSP do Recebedor",
    436: "Data/hora do envio da mensagem está inválida",
    437: "Prazo para a devolução do PIX foi ultrapassado",
    438: "Erro no processamento do pagamento",
    439: "Forma de iniciação inválida. Identificador da operação inválido",
    440: "Código ISPB do banco pagador inválido ou inexistente",
    441: "Código ISPB do Banco Recebedor inválido ou inexistente",
    446: "Pagamento não autorizado",
    447: "Erro no processamento",
    448: "Pix não efetivado (NV CÓD SED)",
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!idTransaction || idTransaction === "") {
      setLoading(false);
      setError("Campo Número de lote de pesquisa é obrigatório");
      return;
    }

    try {
      const headers = {
        action: "Invoices:view",
      };
      const response = await api.post(
        "/request_bank/pix_info",
        {
          identificadorPagamento: idTransaction,
        },
        {
          headers,
        }
      );
      setLoading(false);
      setError("");
      setPayment(response.data);
      setListaPix(response.data.listaPix[0]);
      setListaDevolucao(response.data.listaDevolucao[0]);
    } catch (err) {
      setLoading(false);
      setError("Dados não encontrados");
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="col-md-12 col-lg-12 text-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
            </div>

            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Buscar informações de uma transferência PIX
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label">ID transação:</label>
                      <input
                        type="text"
                        autoFocus="focus"
                        className="form-control"
                        value={idTransaction}
                        onChange={(event) => setIdTransaction(event.target.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "17%" }}
                      >
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div className="card">
                <div className="card-header text-white bg-dark">
                  Informações do lote
                </div>
                <div className="card-body">

                  { payment ? (

                <ul className="list-group">

                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Situação</div>
                        {payment.estadoPagamento}
                      </div>
                      
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">ID</div>
                        {payment.descricaoPagamento}
                      </div>
                      
                    </li>
                    
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Agência / Conta</div>
                        {payment.agenciaDebito} / {payment.contaDebito}
                      </div>
                    </li>

                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Beneficiário / Documento</div>
                        {listaPix.nomeBeneficiario} / {listaPix.cpfCnpjBeneficiario}
                      </div>
                    </li>

                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Valor</div>
                        {MoneyEUAtoBR(payment.valorPagamento)} 
                      </div>
                    </li>

                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">Motivo</div>
                        {listaDevolucao.codigoMotivo} - {statusDevolution[listaDevolucao.codigoMotivo]} 
                      </div>
                    </li>

                  </ul>
                  ) : (
                    <div>
                      
                    </div>
                  )}

                </div>
              </div>
            )}
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
