import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import Loader from "../../components/Loader";
import { MoneyEUAtoBR } from "../../components/MoneyEUAtoBR";

export default function Balance() {
  const [error, setError] = useState("");
  const [year, setYear] = useState("");
  const [balance, setBalance] = useState([]);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    if (!year || year === "") {
      setLoading(false);
      setError("Campo ano é obrigatório");
      return;
    }

    try {
      const headers = {
        action: "Balance:view",
      };
      const response = await api.post(
        "/platform_lucrativity",
        {
          year,
        },
        {
          headers,
        }
      );
      setLoading(false);
      setBalance(response.data);
    } catch (err) {
      setLoading(false);
      setError("Dados não encontrados");
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="col-md-12 col-lg-12 text-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
            </div>

            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Pesquisar Extrato financeiro
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-2">
                      <label className="form-label">Ano Base:</label>
                      <input
                        type="text"
                        autoFocus="focus"
                        className="form-control"
                        placeholder="Ex: 2021"
                        value={year}
                        onChange={(event) => setYear(event.target.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "20%" }}
                      >
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card">
              <div className="card-header text-white bg-dark">
                Extrato financeiro
              </div>
              <div className="card-body"> 

               { loading ? (
                  <Loader />
                ) : (

                <div className="row">
                  {balance.map((bal) =>
                    bal.currentBalance ? (
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <strong>Saldo: { MoneyEUAtoBR(bal.currentBalance/100) }</strong>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-4 col-lg-4" style={{fontSize: "14px"}}>
                        <ul key={bal.month} className="list-group">
                          <li className="list-group-item list-group-item-secondary">
                            <strong>Mês: {bal.month}</strong>
                          </li>

                          <li className="list-group-item">
                            Total taxa boleto: {MoneyEUAtoBR(bal.sumInvoices / 100)}
                          </li>

                          <li className="list-group-item">
                            Total taxa saque: {MoneyEUAtoBR(bal.sumWithdrawTaxes / 100)}
                          </li>

                          <li className="list-group-item">
                            Total transf virtual recebidas: {MoneyEUAtoBR(bal.sumVirtualTransReceived / 100)}
                          </li>

                          <li className="list-group-item">
                            Total rendimentos mensais: {MoneyEUAtoBR(bal.sumfinancialReturn / 100)}
                          </li>

                          <li className="list-group-item list-group-item-danger">
                          Total transf virtual enviada: {MoneyEUAtoBR(bal.sumVirtualTransSender / 100)}
                          </li>

                          <li className="list-group-item list-group-item-danger">
                            Total mensalidade CC: {MoneyEUAtoBR(bal.bankTax / 100)}
                          </li>

                          <li className="list-group-item list-group-item-danger">
                            Total saques da plataforma: R${" "}
                            {MoneyEUAtoBR(bal.sumrequestWithdraw / 100)}
                          </li>

                          <li className="list-group-item list-group-item-secondary">
                            Saldo do Mês: {MoneyEUAtoBR(bal.balanceMonth / 100)}
                          </li>
                        </ul>
                        <br></br>
                      </div>
                    )
                  )}
                </div>
                ) }
              </div>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
