import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";

export default function InvoiceReleased() {
  
  const [error, setError] = useState("");
  const [paid_at, setPaidAt] = useState("");
  const [invoices, setInvoices] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    if (!paid_at || paid_at === "") {
      setError("Campo Data Ocorrência é obrigatório");
    }
    try {
      const response = await api.post("/invoice_released", {
        paid_at,
      });
      setInvoices(response.data.message);
    } catch (err) {
      setError("Dados não encontrados");
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">

        <div className="col-md-12 col-lg-12 text-center">
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
      </div>

      <div className="col-md-12 col-lg-12 text-center">
        {invoices && (
          <div className="alert alert-info" role="alert">
            {invoices}
          </div>
        )}
      </div>

      
          <div className="col-md-6 col-lg-6 offset-md-3">
            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Carregar pagamentos por data
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-4">
                      <label className="form-label">Data Ocorrência:</label>
                      <input
                        type="text"
                        autoFocus="focus"
                        className="form-control"
                        placeholder="Ex: 2021-01-01"
                        value={paid_at}
                        onChange={(event) => setPaidAt(event.target.value)}
                      />
                    </div>
                    <div className="col-md-4">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "22%" }}
                      >
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
