import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { NavLink } from "react-router-dom";
import Loader from "../../components/Loader";

export default function ResetPass({ match, history }) {
  const { params } = match;

  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function loadUserData() {
      try {
        const headers = {
          action: "Auth:view",
        };

        const response = await api.get("/user/" + params.id, {
          headers,
        });

        setName(response.data.user.name);
        setLoading(false);
      } catch (error) {
        if (error.response.status >= 400) {
          setError("Usário sem permissão!");
          history.push(`/dashboard`);
        }
      }
    }
    loadUserData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!password || password === "") {
      setError("Senha é obrigatória");
      return false;
    }

    if (!confirmPassword || confirmPassword === "") {
      setError("Confirmar a senha é obrigatório");
      return false;
    }

    try {
      if (password && confirmPassword) {
        const headers = {
          action: "Auth:passwordReset",
        };
        
        await api
          .put(
            "password/reset/" + params.id,
            {
              password,
            },
            {
              headers,
            }
          )
          .then(function (response) {
            setPassword("");
            setConfirmPassword("");
            setError("");
            setLoading(false);
            setAlert("Senha alterada com sucesso");
          })
          .catch(function (error) {
            setLoading(false);
            if (error.response.status === 401) {
              setError(error.response.data.error);
            }
          });
      }
    } catch (err) {
      setLoading(false);
      setError("Dados inválidos");
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="col-md-6 offset-md-3">
          <div className="col-md-12 col-lg-12 text-center">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {alert && (
              <div className="alert alert-success" role="alert">
                {alert}
              </div>
            )}
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div className="card">
              <div className="card-header text-white bg-dark fs-4 text-center">
                Editar usuário
              </div>

              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Nome:</b>
                    </label>
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      value={name}
                      readOnly={true}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Senha:</b>
                    </label>
                    <input
                      name="password"
                      type="password"
                      autoFocus="focus"
                      className="form-control"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Confirmar senha:</b>
                    </label>
                    <input
                      name="confirmPassword"
                      type="password"
                      className="form-control"
                      onChange={(event) =>
                        setConfirmPassword(event.target.value)
                      }
                    />
                  </div>
                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary btn-lg">
                      Atualizar
                    </button>
                    <NavLink className="btn btn-warning btn-lg" to={"/users"}>
                      Voltar
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
