import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { DateEUAtoBR } from "../../components/DateEUAtoBR";
import { MoneyEUAtoBR } from "../../components/MoneyEUAtoBR";
import Loader from "../../components/Loader";

export default function ReportAdminByDate() {
  const [error, setError] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const [totalInvoiceType, setTotalInvoiceType] = useState(false);
  const [loading, setLoading] = useState(false);
  let quantity_records = 0;

  const sumInvoices = (invoices) => {
    let total = 0;
    invoices.map((invoice) => (total += invoice.amount_paid));
    setTotalAmountPaid(total);
  };

  const sumProfit = (invoices) => {
    let total = 0;
    invoices.map((invoice) => (total += invoice.profit));
    setTotalProfit(total);
  };

  const sumInvoiceType = (invoices) => {
    let bank_slip = 0;
    let pix = 0;
    invoices.map((invoice) => (
      invoice.payable_with === 'PIX' ? pix += 1 : bank_slip += 1
    ));
    setTotalInvoiceType({
      'pix': pix,
      'bank_slip': bank_slip
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!searchDate || searchDate === "") {
      setLoading(false);
      setError("Campo Data de pesquisa é obrigatório");
      return;
    }

    const dateSplit = searchDate.split("-");
    const year  = dateSplit[0];
    const month = dateSplit[1];
    const day   = dateSplit[2];

    try {
      const headers = {
        action: "Invoices:view",
      };
      const response = await api.post(
        "/invoices_admin",
        {
          year,
          month,
          day,
          status: "paid",
        },
        {
          headers,
        }
      );
      setLoading(false);
      setError("");
      sumInvoiceType(response.data.invoices);
      setInvoices(response.data.invoices);
      sumInvoices(response.data.invoices);
      sumProfit(response.data.invoices);
      sumInvoiceType(response.data.invoices);
    } catch (err) {
      setLoading(false);
      setError("Dados não encontrados");
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="col-md-12 col-lg-12 text-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
            </div>

            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Pesquisar Faturas baixadas por dia
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label">Data de pesquisa:</label>
                      <input
                        type="date"
                        autoFocus="focus"
                        className="form-control"
                        value={searchDate}
                        onChange={(event) => setSearchDate(event.target.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "20%" }}
                      >
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            { loading ? (
                  <Loader />
                ) : (
            <div className="card">
              <div className="card-header text-white bg-dark">
                Relação de faturas
              </div>
              <div className="card-body">

              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <div className="col-md-12 col-lg-12 text-center">
                    <button type="button" className="btn btn-danger mb-3">
                      Boletos <span className="badge text-bg-secondary">{totalInvoiceType.bank_slip}</span>
                    </button>
                    <button type="button" className="btn btn-success ms-3 mb-3">
                      PIX <span className="badge text-bg-secondary">{totalInvoiceType.pix}</span>
                    </button>
                  </div>
                </div>
              </div>

                <table className="table table-bordered">
                  <thead>
                    <tr className="table-active">
                      <th scope="col">#</th>
                      <th scope="col">Pago em</th>
                      <th scope="col">Beneficiário</th>
                      <th scope="col">Plataforma</th>
                      <th scope="col">ID</th>
                      <th scope="col">Cliente</th>
                      <th scope="col" className="text-center">Pago via</th>
                      <th scope="col">Lucro</th>
                      <th scope="col">Valor</th>
                    </tr>
                  </thead>

                  <tbody style={{ fontSize: "14px" }}>
                    {invoices.map((invoice) => (
                      <tr key={invoice._id}>
                        <td>{++quantity_records}</td>
                        <td>{DateEUAtoBR(invoice.paid_date)}</td>
                        <td>
                            {invoice.payee_details[0].name}<br></br>
                            CNPJ: {invoice.payee_details[0].number_doc}<br></br>
                            Cidade: {invoice.payee_details[0].city}
                        </td>
                        <td>{invoice.payee_details[0].plataform}</td>
                        <td>{invoice._id} 
                        <br></br><br></br>
                        <a 
                          className="btn btn-primary btn-sm"
                          href={'https://api.rswmanager.com.br/v1/invoice/' + invoice._id} 
                          target="_blank" rel="noreferrer" 
                        >
                          ver
                        </a>
                        </td>
                        <td>
                            {invoice.payer.name}<br></br>
                            {invoice.payer.number_doc}
                        </td>
                        <td className="text-center">{invoice.payable_with}</td>
                        <td>{MoneyEUAtoBR(invoice.profit/100)}</td>
                        <td>{MoneyEUAtoBR(invoice.amount_paid/100)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                      <tr className="table-active">
                        <th colSpan="7">Total</th>
                        <th>
                          {MoneyEUAtoBR(totalProfit / 100)}
                        </th>
                        <th>
                          {MoneyEUAtoBR(totalAmountPaid / 100)}
                        </th>
                      </tr>
                    </tfoot>
                </table>
              </div>
            </div>
                ) }
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
