import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { getPublicKey } from "../../services/auth";
import { NavLink } from "react-router-dom";
import Loader from "../../components/Loader";
import { CreateValidate } from "./createValidate";

export default function Create() {
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);

  const [description, setDescription] = useState("");
  const [bankHoliday, setBankHoliday] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();

    setLoading(true);

    const bodyObject = {
      description,
      bank_holiday: bankHoliday,
      user_id: getPublicKey(),
    };

    const isValid = await CreateValidate(bodyObject);

    if (isValid !== true) {
      setError(isValid);
      setLoading(false);
      return false;
    }

    try {
      const headers = {
        action: "FinancialReturn:create",
      };

      await api
        .post("/dayoff", bodyObject, {
          headers,
        })
        .then(function (response) {
          setDescription("");
          setBankHoliday("");
          setError("");
          setAlert("Dados cadastrados com sucesso");
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            setError(error.response.data.error);
          }
        });
    } catch (err) {
      setError("Dados inválidos");
    }

    setLoading(false);
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="col-md-6 offset-md-3">
          <div className="col-md-12 col-lg-12 text-center">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {alert && (
              <div className="alert alert-success" role="alert">
                {alert}
              </div>
            )}
          </div>

          <div className="card">

            <div className="card-header text-white bg-dark fs-4 text-center">
              Cadastrar feriado bancário
            </div>

            {loading ? (
              <Loader />
            ) : (
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Descrição:</b>
                    </label>
                    <input
                      name="description"
                      type="text"
                      className="form-control"
                      placeholder="Ex.: Natal"
                      maxLength="150"
                      onChange={(event) => setDescription(event.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      <b>Dia:</b>
                    </label>
                    <input
                      name="bank_holiday"
                      type="date"
                      value={bankHoliday}
                      className="form-control"
                      onChange={(event) => setBankHoliday(event.target.value)}
                    />
                  </div>

                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary btn-lg">
                      Salvar
                    </button>
                    <NavLink
                      className="btn btn-warning btn-lg"
                      to={"/bank_dayoff"}
                    >
                      Voltar
                    </NavLink>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
