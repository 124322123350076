import * as yup from "yup";

export const CreateValidate = async function (object) {

    const schema = yup.object().shape({
        holder_name: yup.string().required(),
        type_doc: yup.number().required()
            .positive()
            .integer()
            .min(1)
            .max(2),
        number_doc: yup.string()
            .required()
            .min(11)
            .max(14),
        bank: yup.string().required(),
        bank_number: yup.number().required()
            .positive()
            .integer(),
        bank_ag: yup.string().required(),
        bank_cc: yup.string().required(),
        account_type: yup.string().required()
    });

    try {

        await schema.validate(object);

        return true;

    } catch (error) {

        return error.message;
    }
};
