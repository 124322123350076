import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { getPublicKey } from "../../services/auth";
import { NavLink } from "react-router-dom";
import Loader from "../../components/Loader";

export default function Edit({ match, history }) {
  const { params } = match;

  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [profile, setProfile] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadUserData() {
      setLoading(true);
      const headers = {
        action: "Auth:view",
      };
      const response = await api.get("/user/" + params.id, {
        headers,
      });
      setName(response.data.user.name);
      setEmail(response.data.user.email);
      setProfile(response.data.user.profile);
      setLoading(false);
    }
    loadUserData();
  }, [params.id]);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!name || name === "") {
      setError("Nome é obrigatório");
      return false;
    }

    if (!email || email === "") {
      setError("Email é obrigatório");
      return false;
    }

    if (!profile || profile === "") {
      setError("Perfil é obrigatório");
      return false;
    }

    try {
      if (name && email && profile) {
        const headers = {
          action: "Auth:edit",
        };
        await api.put(
          "/user/" + params.id,
          {
            name,
            email,
            profile,
            user_id: getPublicKey(),
          },
          {
            headers,
          }
        ).then(function (response) {

          setName(name);
          setEmail(email);
          setProfile(profile);
          setLoading(false);
          setError("");
          setAlert("Dados atualizados com sucesso");
        })
        .catch(function (error) {
          setLoading(false);
          if (error.response.status === 401) {
            setError(error.response.data.error);
          }
        });
      }
    } catch (err) {

      setLoading(false);
      setError("Dados inválidos");
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="col-md-6 offset-md-3">
          <div className="col-md-12 col-lg-12 text-center">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {alert && (
              <div className="alert alert-success" role="alert">
                {alert}
              </div>
            )}
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div className="card">
              <div className="card-header text-white bg-dark fs-4 text-center">
                Editar usuário
              </div>

              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Nome:</b>
                    </label>
                    <input
                      name="name"
                      type="text"
                      autoFocus="focus"
                      className="form-control"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Email:</b>
                    </label>
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Perfil:</b>
                    </label>
                    <select
                      name="profile"
                      onChange={(event) => setProfile(event.target.value)}
                      className="form-select"
                    >
                      <option value="{profile}" selected>
                        {profile}
                      </option>
                      <option value="ADM">ADM</option>
                      <option value="FINANCEIRO">FINANCEIRO</option>
                      <option value="SUPORTE">SUPORTE</option>
                      <option value="MASTER-SUPORTE">MASTER-SUPORTE</option>
                    </select>
                  </div>
                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary btn-lg">
                      Atualizar
                    </button>
                    <NavLink className="btn btn-warning btn-lg" to={"/users"}>
                      Voltar
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
