import { NavLink } from "react-router-dom";

export const Columns = () => {
  return [
    {
      name: "name",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "profile",
      label: "Perfil",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "_id",
      label: "Ação",
      options: {
        filter: true,
        sort: true,
        align: "center",
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NavLink className="btn btn-primary" to={"/user/edit/" + value}>
              Editar
            </NavLink>
          );
        },
      },
    },
  ];
};
