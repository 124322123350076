import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import Loader from "../../components/Loader";
import api from "../../services/Api";
import PermissionGate from "../../config/PermissionGate";

export default function Dashboard() {
  const [countInvoicePaid, setCountInvoicePaid] = useState(0);
  const [countBankSlipPaid, setCountBankSlipPaid] = useState(0);
  const [countPIXPaid, setCountPIXPaid] = useState(0);
  const [countTransferByDateAndStatus, setCountTransferByDateAndStatus] =
    useState([]);
  const [countTransferByStatus, setCountTransferByStatus] = useState([]);
  const [countInvoiceByStatus, setCountInvoiceByStatus] = useState([]);
  const [countInvoicesWebhookFail, setCountInvoicesWebhookFail] = useState([]);
  const [colorCardGatilho, setColorCardGatilho] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadDashboard() {
      setLoading(true);
      const headers = {
        action: "Financial:view",
      };
      const response = await api.get("/dashboard", {
        headers,
      });
      
      setCountTransferByDateAndStatus(
        response.data.countTransferByDateAndStatus
      );
      setCountPIXPaid(response.data.countPIXPaid);
      setCountBankSlipPaid(response.data.countBankSlipPaid);
      setCountInvoicePaid(response.data.countInvoicePaid);
      setCountTransferByStatus(response.data.countTransferByStatus);
      setCountInvoiceByStatus(response.data.countInvoiceByStatus);
      setCountInvoicesWebhookFail(response.data.searchCountInvoicesWebhookFail);

      const data = new Date();
      setColorCardGatilho(data.getHours() > 12 && data.getSeconds() > 0 && loadTotalTransfers(response.data.countTransferByDateAndStatus) > 0);

      setLoading(false);
    }

    loadDashboard();
  }, []);

  const loadTotalTransfers = (infoCountTransferByDateAndStatus) => {
    let totalComission = 0;

    infoCountTransferByDateAndStatus.map(
      (trans) => (totalComission += trans.count)
    );

    return totalComission;
  };

  return (
    <>
      <NavBar />
      <div className="container">
        {loading ? (
          <Loader />
        ) : (
          <div className="row">
            <PermissionGate module={"Financial"} permissions={"view"}>
              <div className="col-md-6 col-lg-6 text-center">
                <div className="row">
                  <h5>Movimentação do dia</h5>
                  <hr></hr>
                  <div className="col-md-6 col-lg-6 text-center">
                    <div className="card">
                      <h5 className="card-header">Faturas</h5>
                      <div className="card-body">
                        <h5>
                          Qtd. de Faturas baixadas{" "}
                          <span className="badge bg-secondary">
                            {countInvoicePaid}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 text-center">
                    <div className="card">
                      <h5 className="card-header">Boletos</h5>
                      <div className="card-body">
                        <h5>
                          Qtd. de boletos baixados{" "}
                          <span className="badge bg-secondary">
                            {countBankSlipPaid}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 text-center" style={{ marginTop: "5px" }}>
                    <div className="card">
                      <h5 className="card-header">PIX</h5>
                      <div className="card-body">
                        <h5>
                          Qtd. de PIX realizados{" "}
                          <span className="badge bg-secondary">
                            {countPIXPaid}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 text-center">
                    <div className="card">
                      <h5 className="card-header">Gatilhos</h5>
                      <div className="card-body">
                      <h5>
                          Qtd. de boletos não enviados{" "}
                          <span className={"badge " + (colorCardGatilho? 'bg-danger' : 'bg-secondary')}>
                            {countInvoicesWebhookFail}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-12 col-lg-12"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="card">
                      <h5 className="card-header">
                        Detalhamento de transferências
                      </h5>
                      <div className="card-body">
                        {countTransferByDateAndStatus.map((trans) => (
                          <li className="list-group-item d-flex w-100 justify-content-between">
                            {trans._id.status}{" "}
                            <span className="badge bg-secondary">
                              {trans.count}
                            </span>
                          </li>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-6 text-center">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Movimentação geral</h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                      Movimentações desde o início da plataforma
                    </h6>
                    <div className="card">
                      <h5 className="card-header">Faturas</h5>
                      {countInvoiceByStatus.map((invoice) => (
                        <li className="list-group-item d-flex w-100 justify-content-between">
                          {invoice._id.status}{" "}
                          <span className="badge bg-secondary">
                            {invoice.count}
                          </span>
                        </li>
                      ))}
                    </div>
                    <div className="card" style={{ marginTop: "10px" }}>
                      <h5 className="card-header">Transferências</h5>
                      {countTransferByStatus.map((trans) => (
                        <li className="list-group-item d-flex w-100 justify-content-between">
                          {trans._id.status}{" "}
                          <span className="badge bg-secondary">
                            {trans.count}
                          </span>
                        </li>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </PermissionGate>
          </div>
        )}
      </div>
    </>
  );
}
