import { NavLink } from "react-router-dom";

export const Columns = () => {
  return [
    {
      name: "payee",
      label: "Plataforma",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
            return value.plataform || '';
        }
      },
    },
    {
      name: "payee",
      label: "Beneficiário",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
            return value.name || '';
        }
      },
    },
    {
      name: "payee",
      label: "CPF/CNPJ",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
            return value.number_doc || '';
        }
      },
    },
    {
      name: "payee",
      label: "Cidade",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
            return value.city || '';
        }
      },
    },
    {
      name: "holder_name",
      label: "Responsável",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "account_type",
      label: "Tipo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bank",
      label: "Banco",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bank_ag",
      label: "Agência",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bank_cc",
      label: "Conta Corrente",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "payee",
      label: "Ação",
      options: {
        filter: true,
        sort: true,
        align: 'center',
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <NavLink
                className="btn btn-primary"
                to={"/bank_account/edit/" + value._id}
              >
                Editar
              </NavLink>
            );
          }
      },
    },
  ];
};
