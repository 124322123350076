import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { getPublicKey } from "../../services/auth";
import { NavLink } from "react-router-dom";
import Loader from "../../components/Loader";
import { CreateValidate } from "./createValidate";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function Edit({ match, history }) {
  const { id } = match.params;

  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(true);
  const [banksPattern, setBanksPattern] = useState([]);
  const [bPattern, setBPattern] = useState(false);
  const [payees, setPayees] = useState([]);

  const [payee, setPayee] = useState("");
  const [holderName, setHolderName] = useState("");
  const [typeDoc, setTypeDoc] = useState(1);
  const [numberDoc, setNumberDoc] = useState("");
  const [bank, setBank] = useState("");
  const [bankNumber, setBankNumber] = useState("");
  const [bankAg, setBankAg] = useState("");
  const [bankCc, setBankCc] = useState("");
  const [bankAgPattern, setBankAgPattern] = useState("");
  const [bankCcPattern, setBankCcPattern] = useState("");
  const [accountType, setAccountType] = useState("Corrente");

  const typeDocs = [
    { id: 1, description: "Pessoa Física" },
    { id: 2, description: "Pessoa Jurídica" },
  ];

  const typeCC = [
    { id: 1, description: "Corrente" },
    { id: 2, description: "Poupança" },
  ];

  const loadBankPatterns = async () => {
    try {
      const headers = {
        action: "AccountBank:view",
      };
      const response = await api.get("/banks_pattern", {
        headers,
      });
      setBanksPattern(response.data.banksPattern);
    } catch (err) {
      setError("Dados não encontrados");
    }
  };

  const loadPayees = async () => {
    try {
      const headers = {
        action: "Payee:list",
      };
      const response = await api.get("/payees", {
        headers,
      });
      if (response.status === 200) {
        let arrayPayee = [];
        const listPayee = response.data.payee;
        listPayee.forEach((element) => {
          arrayPayee.push({
            number_doc: element.number_doc,
            name: element.name,
            platform: element.plataform,
            id: element._id,
          });
        });
        setPayees(arrayPayee);
      }
    } catch (err) {
      setError("Dados não encontrados");
    }
  };

  useEffect(() => {
    loadPayees();
    loadBankPatterns();
    setLoading(false);
  }, [id]);

  const viewBankPattern = (number_bank) => {
    let pattern = banksPattern.find((o) => o.number === number_bank);
    setBank(pattern.name);
    setBankNumber(number_bank);
    setBankAgPattern(pattern.agency);
    setBankCcPattern(pattern.account);
    setBPattern(true);
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setLoading(true);

    const bodyObject = {
      holder_name: holderName,
      type_doc: typeDoc,
      number_doc: numberDoc,
      bank: bank,
      bank_number: bankNumber,
      bank_ag: bankAg,
      bank_cc: bankCc,
      account_type: accountType,
      user_id: getPublicKey(),
    };

    const isValid = await CreateValidate(bodyObject);

      if (isValid !== true) {
        setError(isValid);
        setLoading(false);
        return false;
      }

    try {

        const headers = {
          payee: payee.replace(/"/g, ''),
          action: "AccountBank:create"
        };

        const response = await api.post(
          "/bank_account",
          bodyObject,
          {
            headers,
          }
        );

        if (response.status === 200) {
          setError("");
          setAlert("Dados cadastrados com sucesso");
        }
    } catch (err) {
      setError("Dados inválidos");
    }

    setLoading(false);
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="col-md-6 offset-md-3">
          <div className="col-md-12 col-lg-12 text-center">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {alert && (
              <div className="alert alert-success" role="alert">
                {alert}
              </div>
            )}
          </div>

          <div className="card">
            <div className="card-header text-white bg-dark fs-4 text-center">
              Cadastrar conta bancária
            </div>

            {loading ? (
              <Loader />
            ) : (
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Beneficiário:</b>
                    </label>
                    <Autocomplete
                      id="combo-box-demo"
                      options={payees}
                      getOptionLabel={(option) =>
                        `${option.number_doc} - ${option.platform} - ${option.name}`
                      }
                      style={{ fontSize: "12px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="CNPJ - Plataforma - Razão social"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, newValue) => {
                        setPayee(JSON.stringify(newValue.id, null, " "));
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Titular:</b>
                    </label>
                    <input
                      name="holderName"
                      type="text"
                      className="form-control"
                      onChange={(event) => setHolderName(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Tipo:</b>
                    </label>
                    <select
                      name="typeDoc"
                      onChange={(event) => setTypeDoc(event.target.value)}
                      className="form-select"
                      value={typeDoc}
                    >
                      {typeDocs.map((type) => (
                        <option value={type.id}>{type.description}</option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      <b>Documento:</b>
                    </label>
                    <input
                      name="numberDoc"
                      type="text"
                      className="form-control"
                      onChange={(event) => setNumberDoc(event.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      <b>Banco:</b>
                    </label>
                    <select
                      name="bankNumber"
                      onChange={(event) => viewBankPattern(event.target.value)}
                      className="form-select"
                      value={bankNumber}
                    >
                      <option value="0">-- Selecionar --</option>
                      {banksPattern.map((bankp) => (
                        <option value={bankp.number}>{bankp.name}</option>
                      ))}
                    </select>
                    <input
                      type="hidden"
                      name="bank"
                      value={bank}
                      onChange={(event) => setBank(event.target.value)}
                    />
                  </div>

                  {bPattern ? (
                    <div className="mb-3">
                      <div className="card bg-primary mb-3">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item active">
                            <b>Padrão de dados bancários</b>
                          </li>
                          <li className="list-group-item">
                            Agência: {bankAgPattern}
                          </li>
                          <li className="list-group-item">
                            Conta: {bankCcPattern}
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Tipo de conta:</b>
                    </label>
                    <select
                      name="accountType"
                      onChange={(event) => setAccountType(event.target.value)}
                      className="form-select"
                      value={{ accountType }}
                    >
                      {typeCC.map((type) => (
                        <option value={type.description}>
                          {type.description}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      <b>Agência:</b>
                    </label>
                    <input
                      name="bankAg"
                      type="text"
                      className="form-control"
                      onChange={(event) => setBankAg(event.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      <b>Número da conta:</b>
                    </label>
                    <input
                      name="bankCc"
                      type="text"
                      className="form-control"
                      onChange={(event) => setBankCc(event.target.value)}
                    />
                  </div>

                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary btn-lg">
                      Salvar
                    </button>
                    <NavLink
                      className="btn btn-warning btn-lg"
                      to={"/bank_accounts"}
                    >
                      Voltar
                    </NavLink>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
