import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import MUIDataTable from "mui-datatables";
import { Columns } from "./Columns";
import Loader from "../../components/Loader";

export default function Payee() {
  const [payees, setPayees] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadPayees() {
      setLoading(true);
      const headers = {
        action: "Payee:list",
      };
      const response = await api.get("/payees", {
        headers,
      });
      setPayees(response.data.payee);
      setLoading(false);
    }

    loadPayees();
  }, []);

  const options = {
    selectableRows: false,
  };

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Controle de beneficiários
              </div>
              {loading ? (
                  <Loader />
                ) : (
              <div className="card-body">
                  <MUIDataTable
                    data={payees}
                    columns={Columns()}
                    options={options}
                  />
              </div>
                 )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
