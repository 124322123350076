import React, { useState } from "react";
import { addHours, parseISO } from "date-fns";
import { format, zonedTimeToUtc } from "date-fns-tz";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { ContainerTable } from "./styles";

const getFormatedDate = (currentDate) => {
  return format(
    addHours(zonedTimeToUtc(parseISO(currentDate), "America/Sao_Paulo"), 3),
    "dd/MM/yyyy HH:mm:ss",
    {
      timeZone: "America/Sao_Paulo",
    }
  );
};

const getFormatedMoney = (currentMoney) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(currentMoney);
};

const SignupSchema = Yup.object().shape({
  date: Yup.date().required("Preencha o campo data corretamente!"),
});

export default function TransfersWithdrawalDay() {
  const [listTransferersWithdrawal, setListTransferersWithdrawal] = useState(
    []
  );
  const [valorTotal, setValorTotal] = useState(0);
  const [errors, setErrors] = useState("");
  let quantity_records = 0;

  const sumSaques = (saques) => {
    let total = 0;
    saques.map((saque) => (total += saque.amount));
    setValorTotal(total);
  };

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="col-md-12 col-lg-12 text-center">
              {errors && (
                <div className="alert alert-danger" role="alert">
                  {errors}
                </div>
              )}
            </div>
            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Pesquisar transferêcias de saque
              </div>
              <div className="card-body">
                <Formik
                  initialValues={{ date: "" }}
                  validationSchema={SignupSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    const headers = {
                      action: "Transfers:list",
                    };
                    await api
                      .post(
                        "/transfer/list_all",
                        {
                          current_date: values.date,
                        },
                        {
                          headers,
                        }
                      )
                      .then(
                        (resp) => {
                          setListTransferersWithdrawal(resp.data.transfers);
                          sumSaques(resp.data.transfers);
                        }
                      )
                      .catch((err) => {
                        setSubmitting(false);
                        setErrors({ message: err.message });
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form className="row g2">
                      <div className="col-md-3 col-lg-3">
                        <label htmlFor="date" className="form-label">
                          Data:
                        </label>
                        <Field
                          name="date"
                          id="date"
                          type="date"
                          value={values.date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                        />
                        <ErrorMessage name="date" />
                      </div>
                      <div className="col-md-2">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                          style={{ marginTop: "20%" }}
                        >
                          Pesquisar
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="card">
              <div className="card-header text-white bg-dark">
                Transferêcias de saque
              </div>
              <div className="card-body">
                <ContainerTable className="row">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-active">
                        <th scope="col">#</th>
                        <th scope="col">Solicitado</th>
                        <th scope="col">Beneficiário</th>
                        <th scope="col">Dados Bancários</th>
                        <th scope="col">Plataforma</th>
                        <th scope="col">Tipo</th>
                        <th className="text-center" scope="col">
                          Status
                        </th>
                        <th scope="col">Valor</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listTransferersWithdrawal.map(
                        (transferersWithdrawal) => (
                          <tr key={transferersWithdrawal._id}>
                            <td>{++quantity_records}</td>
                            <td>
                              {getFormatedDate(
                                transferersWithdrawal.created_at
                              )}
                            </td>
                            <td>
                              {transferersWithdrawal.payee.name}
                              <br />
                              {transferersWithdrawal.payee._id}
                              <br />
                              {"CNPJ/CPF: " +
                                transferersWithdrawal.payee.number_doc}
                              <br />
                              {"Cidade: " + transferersWithdrawal.payee.city}
                            </td>
                            {transferersWithdrawal.account_data ? (
                              <td>
                              {transferersWithdrawal.account_data.holder_name || ''}
                              <br />
                              {"CNPJ/ CPF: " +
                                transferersWithdrawal.account_data.number_doc}
                              <br />
                              {"Banco: " +
                                transferersWithdrawal.account_data.bank}
                              <br />
                              {"AG: " +
                                transferersWithdrawal.account_data.bank_ag}

                              <br />
                              {"CC: " +
                                transferersWithdrawal.account_data.bank_cc} - {transferersWithdrawal.account_data.account_type}
                                <br />
                              {"ID: " +
                                transferersWithdrawal._id}
                            </td>
                            ):(
                              <td>{"ID: " + transferersWithdrawal._id}</td>
                            )}
                            
                            <td>{transferersWithdrawal.payee.plataform}</td>
                            <td>{transferersWithdrawal.type_transfer}</td>
                            <td className="text-center">
                              {transferersWithdrawal.status}
                              <br />
                              {getFormatedDate(
                                transferersWithdrawal.updated_at
                              )}
                            </td>
                            <td>
                              {getFormatedMoney(
                                transferersWithdrawal.amount / 100
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colSpan="3">Total</th>
                        <th colSpan="4" className="final-value">
                          {getFormatedMoney(valorTotal / 100)}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </ContainerTable>
              </div>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
