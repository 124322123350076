import React, { useState, useRef } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import {getPublicKey} from "../../services/auth";
import { NavLink} from "react-router-dom";

export default function Create({ history }) {

  let backTopPage = useRef();

  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profile, setProfile] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();

    if (!name || name === "") {
        setError("Nome é obrigatório");
        window.scrollTo({
            behavior: 'smooth',
            top: backTopPage.current.offsetTop 
        });
        return false;
    }

    if (!email || email === "") {
        setError("Email é obrigatório");
        window.scrollTo({
            behavior: 'smooth',
            top: backTopPage.current.offsetTop 
        });
        return false;
    }

    if (!profile || profile === "") {
        setError("Perfil é obrigatório");
        return false;
    }

    if (!password || password === "") {
        setError("Senha é obrigatória");
        window.scrollTo({
            behavior: 'smooth',
            top: backTopPage.current.offsetTop 
        });
        return false;
    }

    if (!confirmPassword || confirmPassword === "") {
        setError("Confirmar a senha é obrigatório");
        window.scrollTo({
            behavior: 'smooth',
            top: backTopPage.current.offsetTop 
        });
        return false;
    }

    if (password !== confirmPassword) {
        setError("Senha está diferente da senha de confirmação");
        window.scrollTo({
            behavior: 'smooth',
            top: backTopPage.current.offsetTop 
        });
        return false;
    }

    try {

        if (name && email && profile) {

            const headers = {
                action: "Auth:create",
              };
            const response = await api.post("/user", {
              name,
              email,
              profile,
              password,
              user_id: getPublicKey()
            },
            {
                headers
            });
    
            setName("");
            setEmail("");
            setProfile("");

            setAlert(
                "Usuário cadastrado com sucesso"
              );
        }

    }catch (err) {
        setError("Dados inválidos");
    }
  }

  return (
    <>
      <NavBar />
      <div ref={backTopPage} className="container">
        <div className="col-md-6 offset-md-3">
          <div className="col-md-12 col-lg-12 text-center">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {alert && (
              <div className="alert alert-success" role="alert">
                {alert}
              </div>
            )}
          </div>

          <div className="card">
            <div className="card-header text-white bg-dark fs-4 text-center">
              Editar usuário
            </div>

            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">
                    <b>Nome:</b>
                  </label>
                  <input
                    name="name"
                    type="text"
                    autoFocus="focus"
                    className="form-control"
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    <b>Email:</b>
                  </label>
                  <input
                    name="email"
                    type="email"
                    className="form-control"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    <b>Perfil:</b>
                  </label>
                  <select 
                    name="profile"
                    onChange={(event) => setProfile(event.target.value)}
                    className="form-select"
                    >
                    <option value="ADM">ADM</option>
                    <option value="FINANCEIRO">FINANCEIRO</option>
                    <option value="SUPORTE">SUPORTE</option>
                    <option value="MASTER-SUPORTE">MASTER-SUPORTE</option>
                   </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    <b>Senha:</b>
                  </label>
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    <b>Confirmar senha:</b>
                  </label>
                  <input
                    name="confirmPassword"
                    type="password"
                    className="form-control"
                    onChange={(event) => setConfirmPassword(event.target.value)}
                  />
                </div>
                <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary btn-lg">
                    Salvar
                    </button>
                    <NavLink
                        className="btn btn-warning btn-lg"
                        to={"/users"}
                    >
                    Voltar
                    </NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
