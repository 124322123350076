import { DateEUAtoBR } from "../../components/DateEUAtoBR";

export const Columns = () => {
  return [
    {
      name: "description",
      label: "Feriado",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bank_holiday",
      label: "Dia",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return DateEUAtoBR(value) || "";
        },
      },
    },
  ];
};