import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { getUser, logout, getPublicKey } from "../services/auth";

import PermissionGate from "../config/PermissionGate";
import './navBar.css';

export default function NavBar() {
  const user = getUser();
  const id = getPublicKey();

  let history = useHistory();

  function eventLogout() {
    logout();
    history.push(`/`);
  }

  function eventAlterPass() {
    history.push(`/user/resetPass/` + id);
  }

  return (
    
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark text-white mb-4">
      <div className="container">
        <NavLink className="nav-link active text-white" to="/dashboard">
          {" "}
          Início
        </NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <PermissionGate module={"Administrative"} permissions={"view"}>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-white"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Administrativo
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <PermissionGate module={"Payee"} permissions={"list"}>
                    <li>
                      <NavLink className="dropdown-item" to="/payee">
                        {" "}
                        Beneficiários{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>
                  <PermissionGate module={"AccountBank"} permissions={"list"}>
                    <li>
                      <NavLink className="dropdown-item" to="/bank_accounts">
                        {" "}
                        Contas Bancárias{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>
                  <PermissionGate module={"Transfers"} permissions={"list"}>
                    <li>
                      <NavLink
                        className="dropdown-item"
                        to="/transfers_withdrawal_day"
                      >
                        {" "}
                        Transferência Saque Dia{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>
                  <PermissionGate module={"Invoices"} permissions={"view"}>
                    <li>
                      <NavLink
                        className="dropdown-item"
                        to="/invoicesReportAdmin"
                      >
                        {" "}
                        Faturas baixadas por dia{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>
                </ul>
              </li>
            </PermissionGate>

            <PermissionGate module={"Financial"} permissions={"view"}>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-white"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Financeiro
                </a>
                <ul className="dropdown-menu">
                  <PermissionGate
                    module={"RequestWithDraw"}
                    permissions={"view"}
                  >
                    <li>
                      <NavLink className="dropdown-item" to="/financial_returns">
                        {" "}
                        Lucro com investimento{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>
                  <PermissionGate
                    module={"RequestWithDraw"}
                    permissions={"view"}
                  >
                    <li>
                      <NavLink className="dropdown-item" to="/bank_dayoff">
                        {" "}
                        Feriados Bancários{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>
                  <PermissionGate
                    module={"RequestWithDraw"}
                    permissions={"view"}
                  >
                    <li>
                      <NavLink className="dropdown-item" to="/request_withdraw">
                        {" "}
                        Solicitar saque{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>
                  <PermissionGate
                    module={"RequestWithDraw"}
                    permissions={"view"}
                  >
                    <li>
                      <NavLink className="dropdown-item" to="/transfers_withdrawal_list">
                        {" "}
                        Saques por Beneficiário{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>
                  <PermissionGate module={"Balance"} permissions={"view"}>
                    <li>
                      <NavLink className="dropdown-item" to="/balance">
                        {" "}
                        Extrato{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/transactions">
                        {" "}
                        Transações por Beneficiário{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/invoices_ranking">
                        {" "}
                        Boletos pagos por plataforma{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/invoice_paid_by_day_rates">
                        {" "}
                        Faturas pagas por período e tipo{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/transfers_withdrawal_taxes">
                        {" "}
                        Taxas bancárias por período{" "}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/balance_major_company">
                        {" "}
                        Balanço mensal Reobote{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="nav-item dropend">
                    <a className="nav-link dropdown-toggle text-black" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Liberações
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <NavLink className="dropdown-item" to="/list_withdrawal_batch">
                          {" "}
                          Transferências{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="dropdown-item" to="/manual_batch_delivery">
                          {" "}
                          Envio manual de lote{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="dropdown-item" to="/list_batch_payments_sent">
                          {" "}
                          Liberar lotes{" "}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </PermissionGate>

            <PermissionGate module={"Support"} permissions={"view"}>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle text-white"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Suporte
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <PermissionGate module={"User"} permissions={"list"}>
                    <li>
                      <NavLink className="dropdown-item" to="/users">
                        {" "}
                        Usuários{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>
                  <PermissionGate
                    module={"RequestBankTransfer"}
                    permissions={"view"}
                  >
                    <li>
                      <NavLink
                        className="dropdown-item"
                        to="/shipment_withdrawals"
                      >
                        {" "}
                        Remessa bancária{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>
                  <PermissionGate module={"RequestTransferManual"} permissions={"view"}>
                    <li>
                      <NavLink className="dropdown-item" to="/request_transfer">
                        {" "}
                        Carregar retorno bancário{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>
                  <PermissionGate module={"Hooks"} permissions={"view"}>
                    <li>
                      <NavLink className="dropdown-item" to="/platform_trigger">
                        {" "}
                        Gatilhos por plataforma{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>

                  <PermissionGate module={"Hooks"} permissions={"view"}>
                    <li>
                      <NavLink className="dropdown-item" to="/check_invoice">
                        {" "}
                        Verificar pagamento Boleto{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>

                  <PermissionGate module={"Hooks"} permissions={"view"}>
                    <li>
                      <NavLink className="dropdown-item" to="/check_pix">
                        {" "}
                        Verificar pagamento PIX{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>

                  <PermissionGate module={"Hooks"} permissions={"view"}>
                    <li>
                      <NavLink className="dropdown-item" to="/check_invoice_by_linedigitable">
                        {" "}
                        Buscar boleto por linha digitável{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>

                  <PermissionGate module={"Hooks"} permissions={"view"}>
                    <li>
                      <NavLink className="dropdown-item" to="/check_invoice_by_cpf">
                        {" "}
                        Buscar boleto por CPF{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>

                  <PermissionGate module={"Hooks"} permissions={"view"}>
                    <li>
                      <NavLink className="dropdown-item" to="/search_batch_information">
                        {" "}
                        Buscar informações de um lote{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>

                  <PermissionGate module={"Hooks"} permissions={"view"}>
                    <li>
                      <NavLink className="dropdown-item" to="/search_pix_information">
                        {" "}
                        Buscar informações tranferência PIX{" "}
                      </NavLink>
                    </li>
                  </PermissionGate>
                </ul>
              </li>
            </PermissionGate>
          </ul>
          <ul className="navbar-nav mb-2 mb-lg-0 dropdown-menu-end">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle text-white"
                href="/"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {user}
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <button
                    className="dropdown-item"
                    onClick={(event) => eventAlterPass()}
                  >
                    Alterar senha
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={(event) => eventLogout()}
                  >
                    Sair
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
