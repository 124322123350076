import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { getPublicKey } from "../../services/auth";
import { NavLink } from "react-router-dom";
import Loader from "../../components/Loader";
import { CreateValidate } from "./createValidate";

export default function Edit({ match, history }) {

  const { id } = match.params;

  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);

  const [reference, setReference] = useState("");
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    async function loadFinancialReturnData() {
      try {
        const headers = {
          action: "FinancialReturn:create",
        };
        const response = await api.get("/financial_return/" + id, {
          headers,
        });

        setReference(response.data.financialReturn[0].reference);
        loadConvertMoney((response.data.financialReturn[0].amount).toString());
      } catch (err) {
        setError("Dados não encontrados");
      }
      setLoading(false);
    }
    loadFinancialReturnData();
  },[id]);

  const loadConvertMoney = (value) => {
    value = value.replace(/[.,()\s]/g, '');
    if (!isNaN(value)) {
      const amountReal = (value/100).toLocaleString('pt-br', {minimumFractionDigits: 2});
      setAmount(amountReal); 
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setLoading(true);
    
    const bodyObject = {
      reference,
      amount: parseInt(amount.replace(/[.,()\s]/g, '')),
      user_id: getPublicKey(),
    };

    const isValid = await CreateValidate(bodyObject);

    if (isValid !== true) {
      setError(isValid);
      setLoading(false);
      return false;
    }

    try {
      const headers = {
        action: "FinancialReturn:edit",
      };

      await api
        .put("/financial_return/" + id, bodyObject, {
          headers,
        })
        .then(function (response) {
          setError("");
          setLoading(false);
          setAlert("Dados alterados com sucesso");
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            setError(error.response.data.error);
          }
        });
    } catch (err) {
      setError("Dados inválidos");
    }

    setLoading(false);
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="col-md-6 offset-md-3">
          <div className="col-md-12 col-lg-12 text-center">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {alert && (
              <div className="alert alert-success" role="alert">
                {alert}
              </div>
            )}
          </div>

          <div className="card">
            <div className="card-header text-white bg-dark fs-4 text-center">
              Editar lucro de investimento
            </div>

            {loading ? (
              <Loader />
            ) : (
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Referência:</b>
                    </label>
                    <input
                      name="reference"
                      type="text"
                      className="form-control"
                      placeholder="2021-10"
                      maxLength="7"
                      value={reference}
                      onChange={(event) => setReference(event.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      <b>Valor:</b>
                    </label>
                    <input
                      name="amount"
                      type="text"
                      value={amount}
                      className="form-control"
                      onChange={(event) => loadConvertMoney(event.target.value)}
                    />
                  </div>

                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary btn-lg">
                      Salvar
                    </button>
                    <NavLink
                      className="btn btn-warning btn-lg"
                      to={"/financial_returns"}
                    >
                      Voltar
                    </NavLink>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
