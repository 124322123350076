import React from "react";
function Error404() {
    return (
        <div className="container">
            <div className="row text-center">
                <h1>Error 404</h1>
                <h4>Page not found!</h4>
            </div>
        </div>
    );
}

export default Error404;