import { format, zonedTimeToUtc } from "date-fns-tz";
import { parseISO } from "date-fns";

export const DateEUAtoBR = (americanDate) => {
  return format(
    zonedTimeToUtc(parseISO(americanDate), "America/Sao_Paulo"),
    "dd/MM/yyyy HH:mm:ss",
    {
      timeZone: "America/Sao_Paulo",
    }
  );
};
