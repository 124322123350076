import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { NavLink } from "react-router-dom";
import api from "../../services/Api";
import Loader from "../../components/Loader";
import MUIDataTable from "mui-datatables";
import {Columns} from './Columns';

export default function FinancialReturn() {

  const [financialReturns, setFinancialReturns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function loadFinancialReturns() {
      const headers = {
        action: "FinancialReturn:list",
      };
      const response = await api.get("/financial_return",{
        headers
      });
      setLoading(false);
      setFinancialReturns(response.data.financialReturn);
    }

    loadFinancialReturns();
  }, []);

  const options = {
    selectableRows: false
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                <div className="row">
                  <div className="col-md-10 col-lg-10">
                    Listagem de lucro mensal com investimento
                  </div>

                  <div className="col-md-2 col-lg-2">
                    <NavLink className="btn btn-success" to={"/financial_return"}>
                      Incluir
                    </NavLink>
                  </div>
                </div>
              </div>
              { loading ? (
                  <Loader />
                ) : (
              <div className="card-body">
                <MUIDataTable
                  data={financialReturns}
                  columns={Columns()}
                  options={options}
                />
              </div>
                )} 
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
