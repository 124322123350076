import React, { useState } from "react";
import { addHours, parseISO } from "date-fns";
import { format, zonedTimeToUtc } from "date-fns-tz";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { ContainerTable } from "./styles";
import Loader from "../../components/Loader";

const getFormatedDate = (currentDate) => {
  return format(
    addHours(zonedTimeToUtc(parseISO(currentDate), "America/Sao_Paulo"), 3),
    "dd/MM/yyyy HH:mm:ss",
    {
      timeZone: "America/Sao_Paulo",
    }
  );
};

const getFormatedMoney = (currentMoney) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(currentMoney);
};

const SignupSchema = Yup.object().shape({
  date: Yup.date().required("Preencha o campo data corretamente!"),
});

export default function List() {
  const [listTransferersWithdrawal, setListTransferersWithdrawal] = useState(null);
  const [errors, setErrors] = useState("");
  const [valorTotal, setValorTotal] = useState(0);
  const [alert, setAlert] = useState("");
  const [currentDateBatch, setCurrentDateBatch] = useState({});
  const [loading, setLoading] = useState(false);

  let quantity_records = 0;
  let route = '';
  let parameter = {};
  let headers = {};

  const items = [
    { id: 1, label: 'Sim' },
    { id: 2, label: 'Não' },
  ];

  const sumSaques = (saques) => {
    let total = 0;
    saques.map((saque) => (total += saque.amount));
    setValorTotal(total);
  };

  const handleSelectChange = async (event) => {
    setLoading(true);

    const selectedItemId = parseInt(event.target.value);
    const withdrawalValue = event.target.options[event.target.selectedIndex].getAttribute('data-withdrawal-value');

    let situation = selectedItemId === 1 ? 'deferir' : 'indeferir';

    if (window.confirm(`Deseja realmente ${situation} o saque de código ${withdrawalValue}?`)) {

      parameter = {
        released: selectedItemId,
      }

      headers = {
        action: "Transfers:list",
      };

      route = `/transfer/${withdrawalValue}`;

      try {
        await handleRequestEndpoint('PUT', route, parameter, headers);
        await handleSearchForWithdrawals(currentDateBatch)

        setAlert("Transferência adicionada ao lote");
        setLoading(false);
      } catch (error) {
        setErrors(error.message);
        setLoading(false);
      }
    }

    selectElement(withdrawalValue, '');
  };

  function selectElement(id, valueToSelect) {
    let element = document.getElementById(id);
    element.value = valueToSelect;
  }

  const handleSearchForWithdrawals = async (values) => {
    setLoading(true);
    setCurrentDateBatch(values)

    parameter = {
      currentDate: values.date,
    }

    headers = {
      action: "Transfers:list",
    };

    route = "/transfer/list_batch";

    try {
      const responseWithdrawal = await handleRequestEndpoint('POST', route, parameter, headers);

      if (responseWithdrawal.data.transfers !== false) {
        setListTransferersWithdrawal(responseWithdrawal.data.transfers);
        sumSaques(responseWithdrawal.data.transfers);
      } else {
        setListTransferersWithdrawal([]);
      }
      setLoading(false);
    } catch (error) {
      setErrors(error.message);
      setLoading(false);
    }
  }

  const handleRequestEndpoint = async (method, route, parameter, headers) => {

    return await api
    [method.toLowerCase()](
      route,
      parameter,
      {
        headers
      },
    )
      .catch((error) => {
        throw error;
      });
  }

  return (
    <>
      {loading ? <Loader />
        : (
          <>
            <NavBar />
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12">

                  <div className="col-md-12 col-lg-12 text-center">
                    {errors && (
                      <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        {errors}
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                    )}

                    {alert && (
                      <div className="alert alert-success alert-dismissible fade show" role="alert">
                        {alert}
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                    )}
                  </div>

                  <div className="card" style={{ marginBottom: "1%" }}>
                    <div className="card-header text-white bg-dark">
                      Pesquisar transferências
                    </div>
                    <div className="card-body">
                      <Formik
                        initialValues={{ date: typeof currentDateBatch.date === 'undefined' ? "" : currentDateBatch.date }}
                        validationSchema={SignupSchema}
                        onSubmit={handleSearchForWithdrawals}
                      >
                        {({
                          values,
                          handleChange,
                          handleBlur,
                          isSubmitting,
                        }) => (
                          <Form className="row g2">
                            <div className="col-md-3 col-lg-3">
                              <label htmlFor="date" className="form-label">
                                Data:
                              </label>
                              <Field
                                name="date"
                                id="date"
                                type="date"
                                value={values.date}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                              <ErrorMessage name="date" />
                            </div>
                            <div className="col-md-2">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                                style={{ marginTop: "20%" }}
                              >
                                Pesquisar
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  {
                    listTransferersWithdrawal !== null && listTransferersWithdrawal.length > 0 ? (
                      <div className="card">
                        <div className="card-header text-white bg-dark">
                          Transferências
                        </div>
                        <div className="card-body">
                          <ContainerTable className="row">
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr className="table-active">
                                    <th scope="col">#</th>
                                    <th scope="col">Autorizar</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Solicitado</th>
                                    <th scope="col">Beneficiário</th>
                                    <th scope="col">Dados Bancários</th>
                                    <th scope="col">Plataforma</th>
                                    <th className="text-center" scope="col">Status</th>
                                    <th scope="col">Valor</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {listTransferersWithdrawal.map(transferersWithdrawal => (
                                    <tr key={transferersWithdrawal._id}>
                                      <td>
                                        {++quantity_records}
                                      </td>
                                      <td>
                                        <select multiple={false} onChange={handleSelectChange} className="form-control" style={{ width: '150px' }} id={transferersWithdrawal._id}>
                                          <option key={`${transferersWithdrawal._id}0`} value="">-- Selecione --</option>
                                          {items.map(item => (
                                            <option key={`${transferersWithdrawal._id}${item.id}`} value={item.id} data-withdrawal-value={transferersWithdrawal._id}>
                                              {item.label}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                      <td>

                                      <span className={"badge " + (transferersWithdrawal.bank_account.transfer_type === 'TED' ? 'bg-danger' : 'bg-secondary')}>
                                        {transferersWithdrawal.bank_account.transfer_type}
                                      </span>
                                      </td>
                                      <td>
                                        {getFormatedDate(transferersWithdrawal.created_at)}
                                      </td>
                                      <td>
                                        {transferersWithdrawal.payee.name}
                                        <br />
                                        {transferersWithdrawal.payee._id}
                                        <br />
                                        {`CNPJ/CPF: ${transferersWithdrawal.payee.number_doc}`}
                                        <br />
                                        {`Cidade: ${transferersWithdrawal.payee.city}`}
                                      </td>
                                      <td>
                                        {transferersWithdrawal.bank_account.holder_name}
                                        <br />
                                        {`CNPJ/ CPF: ${transferersWithdrawal.bank_account.number_doc}`}
                                        <br />
                                        {`Banco: ${transferersWithdrawal.bank_account.bank}`}
                                        <br />
                                        {`AG: ${transferersWithdrawal.bank_account.bank_ag}`}
                                        <br />
                                        {`CC: ${transferersWithdrawal.bank_account.bank_cc} - ${transferersWithdrawal.bank_account.account_type}`}
                                        <br />
                                        {`ID: ${transferersWithdrawal._id}`}
                                      </td>
                                      <td>
                                        {transferersWithdrawal.payee.plataform}
                                      </td>
                                      <td className="text-center">
                                        {transferersWithdrawal.status}
                                        <br />
                                        {getFormatedDate(transferersWithdrawal.updated_at)}
                                      </td>
                                      <td>
                                        {getFormatedMoney(transferersWithdrawal.amount / 100)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <th colSpan={4}>Total</th>
                                    <th colSpan={4} className="final-value">
                                      {getFormatedMoney(valorTotal / 100)}
                                    </th>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </ContainerTable>
                        </div>
                      </div>
                    ) :
                      (
                        listTransferersWithdrawal !== null ? (
                          <div className="alert alert-success alert-dismissible fade show" role="alert">
                            Não existe transferências a ser exibido
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>
                        ) :
                          <></>
                      )
                  }
                  <br></br>
                </div>
              </div>
            </div>
          </>
        )}
    </>
  );
}
