import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import Loader from "../../components/Loader";

export default function CheckPix() {

  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (invoiceId === null || invoiceId === "") {
      setLoading(false);
      setError("Data de ID da fatura PIX é obrigatória");
      return;
    }

    try {
      const headers = {
        action: "RequestBankTransfer:view",
      };
      
      await api.post(
        "/pix/released",
        {
          id: invoiceId,
        },
        {
          headers,
        }
      );

      setError("");
      setLoading(false);

      window.open("https://api.rswmanager.com.br/v1/invoice/"+ invoiceId);

    } catch (err) {
      setAlert("");
      setLoading(false);
      setError(err.response.data.error);
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {loading ? (
              <Loader />
            ) : (
              <div className="col-md-12 col-lg-12 text-center">
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}

                {alert && (
                  <div className="alert alert-success" role="alert">
                    {alert}
                  </div>
                )}
              </div>
            )}

            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Verificar pagamento PIX 
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <label className="form-label">
                      <b>Identificação da fatura:</b>
                    </label>
                    <input
                      type="text"
                      autoFocus="focus"
                      className="form-control"
                      placeholder="Ex.: 62582b2e6e39541b6d4b1aa0"
                      value={invoiceId}
                      onChange={(event) => setInvoiceId(event.target.value)}
                    />
                  </div>
                  
                  <div className="d-grid gap-2">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ marginTop: "5%" }}
                    >
                      Verificar
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
