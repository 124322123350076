import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import Loader from "../../components/Loader";

export default function ManualBatchDelivery() {
  
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [numberPixSent, setNumberPixSent] = useState(null);
  const [transactionPix, setTransactionPix] = useState(false);

  async function handleSubmit(event) {
  event.preventDefault();
  setLoading(true);

    try {
      const headers = {
        action: "Transfers:list",
      };
      const response = await api.get("/transfer/send_pix", {
        headers
      });
      setNumberPixSent(response.data.number_pix_sent);
      setTransactionPix(true);
      setLoading(false);
    } catch (err) {
      console.log('error', err);
      setError("Dados não encontrados");
      setTransactionPix(false);
      setLoading(false);
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">

        <div className="col-md-12 col-lg-12 text-center">
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
      </div>

      <div className="col-md-12 col-lg-12 text-center">
        {transactionPix && (
          <div className="alert alert-info" role="alert">
            Quantidade transferências válidas {numberPixSent}
          </div>
        )}
      </div>

      
          <div className="col-md-6 col-lg-6 offset-md-3">
            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Envio manual de lote
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                  { loading ? (
                  <Loader />
                  ) : (
                    <div className="col-md-6 col-lg-6 offset-md-4">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "22%" }}
                      >
                        Executar envio
                      </button>
                    </div>
                  ) }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
