import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import Loader from "../../components/Loader";
import { DateEUAtoBR } from "../../components/DateEUAtoBR";
import { MoneyEUAtoBR } from "../../components/MoneyEUAtoBR";

export default function CheckInvoiceByCPF() {
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [cpf, setCpf] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [totalInvoices, setTotalInvoices] = useState(0);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    setAlert("");

    // Validação simples de CPF
    if (!/^\d{11}$/.test(cpf)) {
      setLoading(false);
      setError("CPF inválido. Digite 11 números.");
      return;
    }

    try {
      const response = await api.post("/invoice_find_by_cpf", { cpf }, {
        headers: { action: "RequestBankTransfer:view" },
      });

      setTotalInvoices(response.data.total_invoices);
      setInvoices(response.data.invoices);
    } catch (err) {
      setError(err.response?.data?.error || "Erro na consulta.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {loading ? (
              <Loader />
            ) : (
              <div className="col-12 text-center">
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                {alert && (
                  <div className="alert alert-success" role="alert">
                    {alert}
                  </div>
                )}
              </div>
            )}

            <div className="card mb-3">
              <div className="card-header text-white bg-dark">
                Buscar faturas por CPF
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label"><b>CPF (somente números):</b></label>
                    <input
                      type="text"
                      autoFocus
                      className="form-control"
                      placeholder="Ex.: 99999999999"
                      value={cpf}
                      maxLength={11}
                      onChange={(e) => setCpf(e.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn btn-primary w-100">
                    Consultar
                  </button>
                </form>
              </div>
            </div>

            {totalInvoices > 0 && (
              <div className="card">
                <div className="card-header text-white bg-dark">
                  Total de {totalInvoices} fatura(s) encontrada(s) para o CPF {cpf}
                </div>
                <div className="card-body">
                  {invoices.map((invoice) => (
                    <div
                      key={invoice._id}
                      className="card mb-3"
                      style={{ fontSize: "12px", backgroundColor: "#f5f5f5" }}
                    >
                      <div className="card-body">
                        <ul className="list-group mb-2">
                          <li className="list-group-item active">
                            <b>{invoice.payable_with === 'bank_slip' ? 'Boleto' : 'PIX'}</b>
                          </li>
                          <li className="list-group-item">
                            Emitida em: <b>{DateEUAtoBR(invoice.createAt)}</b>
                          </li>
                          <li className="list-group-item">
                            Emitida por: <b>{invoice.payee.name}</b> <br />
                            CNPJ: {invoice.payee.number_doc} | {invoice.payee.city} - {invoice.payee.state}
                          </li>
                          <li className="list-group-item">Plataforma: <b>{invoice.description}</b></li>
                          <li className="list-group-item">Referente: <b>{invoice.message}</b></li>
                          <li className="list-group-item">Vencimento: <b>{DateEUAtoBR(invoice.due_date)}</b></li>
                          <li className="list-group-item">Valor: <b>{MoneyEUAtoBR(invoice.amount / 100)}</b></li>
                          <li className="list-group-item">Situação: <b>{invoice.status}</b></li>
                          {invoice.status === 'paid' && (
                            <li className="list-group-item list-group-item-success">
                              Paga em: <b>{DateEUAtoBR(invoice.paid_date)}</b>
                            </li>
                          )}
                          <li className="list-group-item">
                            <a
                              className="btn btn-primary btn-sm"
                              href={`https://api.rswmanager.com.br/v1/invoice/${invoice._id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Visualizar
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
