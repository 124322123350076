import * as yup from "yup";

export const CreateValidate = async function (object) {

    const schema = yup.object().shape({
        bank_holiday: yup.string().required()
            .min(10)
            .max(10),
        description: yup.string()
            .required()
            .min(5)
            .max(150)
    });

    try {

        await schema.validate(object);

        return true;

    } catch (error) {

        return error.message;
    }
};
