import { DateEUAtoBR } from "../../components/DateEUAtoBR";
import { MoneyEUAtoBR } from "../../components/MoneyEUAtoBR";
import { NavLink } from "react-router-dom";

export const Columns = () => {
  return [
    {
      name: "reference",
      label: "Base",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Valor",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return MoneyEUAtoBR(value/100) || "";
        },
      },
    },
    {
      name: "created_at",
      label: "Lançado em",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return DateEUAtoBR(value) || "";
        },
      },
    },
    {
      name: "_id",
      label: "Ação",
      options: {
        filter: true,
        sort: true,
        align: 'center',
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <NavLink
                className="btn btn-primary"
                to={"/financial_return_edit/" + value}
              >
                Editar
              </NavLink>
            );
          }
      },
    },
  ];
};
