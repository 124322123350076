import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import Loader from "../../components/Loader";

const getFormatedMoney = (currentMoney) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(currentMoney);
};

const SignupSchema = Yup.object().shape({
  date: Yup.date().required("Preencha o campo data corretamente!"),
});

export default function ListSent() {
  const [listTransferersWithdrawal, setListTransferersWithdrawal] = useState(null);
  const [errors, setErrors] = useState("");
  const [alert, setAlert] = useState("");
  const [currentDateBatch, setCurrentDateBatch] = useState({});
  const [loading, setLoading] = useState(false);

  const statusLote = {
    1: "Requisição com todos os lançamentos com dados consistentes",
    2: "Requisição com ao menos um dos lançamentos com dados inconsistentes",
    3: "Requisição com todos os lançamentos com dados inconsistentes",
    4: "Requisição pendente de ação pelo Conveniado : falta autorizar o pagamento",
    5: "Requisição em processamento pelo Banco",
    6: "Requisição Processada",
    7: "Requisição Rejeitada",
    8: "Preparando remessa não liberada",
    9: "Requisição liberada via API",
    10: "Preparando remessa liberada"
  };

  let route = '';
  let parameter = {};
  let headers = {};

  const handleSearchForWithdrawals = async (values) => {
    setLoading(true);
    setCurrentDateBatch(values)

    parameter = {
      currentDate: values.date,
    }

    headers = {
      action: "Transfers:list",
    };

    route = "/request_bank_by_date";

    try {
      const responseWithdrawal = await handleRequestEndpoint('POST', route, parameter, headers);

      if (responseWithdrawal.data.batch_of_payments !== false) {

        let array_batches = [];

        responseWithdrawal.data.batch_of_payments.map(item => {

          if (typeof item.request_identification !== 'undefined') {

            array_batches.push({
              'information_batch': {
                'batch': item.request_identification,
                'situation': {
                  'code': item.request_status,
                  'message': statusLote[item.request_status],
                }
              },
              'itens': []
            });
          } else {
            array_batches[array_batches.length - 1].itens.push(item);
          }

        })

        setListTransferersWithdrawal(array_batches);
      } else {
        setListTransferersWithdrawal([]);
      }

      setLoading(false);
    } catch (error) {
      setErrors(error.message);
      setLoading(false);
    }
  }

  const handleReleaseBatch = async (lote) => {
    setLoading(true);

    parameter = {
      requestIdentification: lote,
    }

    headers = {
      action: "Transfers:list",
    };

    route = "/request_bank/batch_release";

    try {
      await handleRequestEndpoint('POST', route, parameter, headers);

      await handleSearchForWithdrawals(currentDateBatch)
      setAlert("Lote enviado");
      setLoading(false);
    } catch (error) {
      setErrors(error.message);
      setLoading(false);
    }
  }

  const handleRequestEndpoint = async (method, route, parameter, headers) => {

    return await api
    [method.toLowerCase()](
      route,
      parameter,
      {
        headers
      },
    )
      .catch((error) => {
        throw error;
      });
  }

  return (
    <>
      {loading ? <Loader />
        : (
          <>
            <NavBar />
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12">

                  <div className="col-md-12 col-lg-12 text-center">
                    {errors && (
                      <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        {errors}
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                    )}

                    {alert && (
                      <div className="alert alert-success alert-dismissible fade show" role="alert">
                        {alert}
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                    )}
                  </div>

                  <div className="card" style={{ marginBottom: "1%" }}>
                    <div className="card-header text-white bg-dark">
                      Pesquisar lote de pagamentos
                    </div>
                    <div className="card-body">
                      <Formik
                        initialValues={{ date: typeof currentDateBatch.date === 'undefined' ? "" : currentDateBatch.date }}
                        validationSchema={SignupSchema}
                        onSubmit={handleSearchForWithdrawals}
                      >
                        {({
                          values,
                          handleChange,
                          handleBlur,
                          isSubmitting,
                        }) => (
                          <Form className="row g2">
                            <div className="col-md-3 col-lg-3">
                              <label htmlFor="date" className="form-label">
                                Data:
                              </label>
                              <Field
                                name="date"
                                id="date"
                                type="date"
                                value={values.date}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                              />
                              <ErrorMessage name="date" />
                            </div>
                            <div className="col-md-2">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                                style={{ marginTop: "20%" }}
                              >
                                Pesquisar
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                  {
                    listTransferersWithdrawal !== null && listTransferersWithdrawal.length > 0 ? (
                      <div className="card">
                        <div className="card-header text-white bg-dark">
                          Lotes a serem liberados
                        </div>
                        <div className="card-body">
                          <div className="accordion" id="accordionExample">
                            {
                              listTransferersWithdrawal.map((transferersWithdrawal, index) => (
                                <div key={index} className="accordion-item">
                                  <h2 className="accordion-header" id={`heading${transferersWithdrawal.information_batch.batch}`}>
                                    <button className="accordion-button collapsed text-primary fw-bold" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${transferersWithdrawal.information_batch.batch}`} aria-expanded="false" aria-controls={`collapse${transferersWithdrawal.information_batch.batch}`}>
                                      Lote {transferersWithdrawal.information_batch.batch} - Code: {transferersWithdrawal.information_batch.situation.message}
                                    </button>
                                  </h2>
                                  <div id={`collapse${transferersWithdrawal.information_batch.batch}`} className="accordion-collapse collapse" aria-labelledby={`heading${transferersWithdrawal.information_batch.batch}`} data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                      <div className="container">
                                        <div className="row">
                                          <div className="col-md-2"><strong>Lote</strong></div>
                                          <div className="col-md-3"><strong>Código do pagamento</strong></div>
                                          <div className="col-md-3"><strong>Dados bancários</strong></div>
                                          <div className="col-md-2"><strong>Valor</strong></div>
                                          <div className="col-md-2">
                                            <button type="button" className="btn btn-success" onClick={() => handleReleaseBatch(transferersWithdrawal.information_batch.batch)}>Liberar lote {transferersWithdrawal.information_batch.batch}</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    {transferersWithdrawal.itens.map((withdrawal, indexInterno) => (

                                      <>
                                        {
                                          indexInterno > 0 ?
                                            <hr />
                                            :
                                            <></>
                                        }
                                        <div key={indexInterno} className="accordion-body">
                                          <div className="container">
                                            <div className="row">
                                              <div className="col-md-2">{withdrawal.request_id}</div>
                                              <div className="col-md-3">{withdrawal.payment_id}</div>
                                              <div className="col-md-3">
                                                {withdrawal.account_data.holder_name}
                                                <br />
                                                {`Documento: ${withdrawal.account_data.number_doc}`}
                                              </div>
                                              <div className="col-md-2">{getFormatedMoney(withdrawal.amount / 100)}</div>
                                              <div className="col-md-2"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </>

                                    ))}
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    ) :
                      (
                        listTransferersWithdrawal !== null ? (
                          <div className="alert alert-success alert-dismissible fade show" role="alert">
                            Não existe lote a ser exibido
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>
                        ) :
                          <></>
                      )
                  }
                  <br></br>
                </div>
              </div>
            </div>
          </>
        )}
    </>
  );
}
