import React, { useState } from "react";
import api from "../../services/Api";
import { login, setUser, setPermissions, setPublicKey } from "../../services/auth";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "../../components/Loader";

export default function Login({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const recaptchaRef = React.useRef();

  async function handleSubmit(event) {
    event.preventDefault();

    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    setLoading(true);

    if (!email || !password) {
      setError("Campos de e-mail e senha são obrigatórios");
      setLoading(false);

      return false;
    }

    try {

      const response = await api.post("/auth", {
        email: email,
        password,
        token_google: token
      });

      login(response.data.token);
      setUser(response.data.user.name);
      setPublicKey(response.data.user.id);
      setPermissions(JSON.stringify(response.data.permissions));
      setLoading(false);

      history.push(`/dashboard`);

    } catch (err) {
      setError("Usuário ou senha inválidos");
      setLoading(false);
    }
  }
  return (
    <>
      {loading ? <Loader /> : (
        <div className="col-md-4 offset-md-4">

          <div className="col-md-12 col-lg-12 text-center mt-5">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
          </div>

          <div className="card">
            <div className="card-header text-white bg-dark fs-4 text-center">
              Autenticação de usuários
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">
                    <b>E-mail:</b>
                  </label>
                  <input
                    name="email"
                    type="email"
                    autoFocus="focus"
                    placeholder="Digite seu e-mail"
                    className="form-control form-control-lg"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    <b>Senha:</b>
                  </label>
                  <input
                    name="pass"
                    type="password"
                    placeholder="Digite sua senha"
                    className="form-control form-control-lg"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </div>
                <div className="mb-3 d-flex justify-content-center">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                    size="invisible"
                    theme="dark"
                    badge="bottomleft"
                  />
                </div>
                <div className="d-grid gap-2">
                  <button type="submit" className="btn btn-primary btn-lg">
                    Acessar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
