import * as yup from "yup";

export const CreateValidate = async function (object) {

    const schema = yup.object().shape({
        amount: yup.number().required()
            .positive()
            .integer()
            .min(1),
        reference: yup.string()
            .required()
            .min(7)
            .max(7)
    });

    try {

        await schema.validate(object);

        return true;

    } catch (error) {

        return error.message;
    }
};
