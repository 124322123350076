import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import Loader from "../../components/Loader";

export default function InvoiceRanking() {
  const [error, setError] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!startDate || startDate === "") {
      setLoading(false);
      setError("Campo Data Inicial de pesquisa é obrigatório");
      return;
    }

    if (!endDate || endDate === "") {
        setLoading(false);
        setError("Campo Data Inicial de pesquisa é obrigatório");
        return;
    }

    try {
      const headers = {
        action: "InvoiceRanking:view",
      };
      const response = await api.post(
        "/report/invoices_ranking",
        {
            startDate,
            endDate
        },
        {
          headers,
        }
      );

      setLoading(false);
      setError("");
      setInvoices(response.data.invoices_list);

    } catch (err) {
      setLoading(false);
      setError("Dados não encontrados");
    }
  }

  const loadTotalTransfers = () => {
    
    let totalQuant = 0;

    invoices.map((trans) => 
        totalQuant += trans.count
    )

    return totalQuant;
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="col-md-12 col-lg-12 text-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
            </div>

            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Pesquisar ranking de boletos pagos
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label">Período:</label>
                      <input
                        type="date"
                        autoFocus="focus"
                        className="form-control"
                        value={startDate}
                        onChange={(event) => setStartDate(event.target.value)}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">Até:</label>
                      <input
                        type="date"
                        autoFocus="focus"
                        className="form-control"
                        value={endDate}
                        onChange={(event) => setEndDate(event.target.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "20%" }}
                      >
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            { loading ? (
                  <Loader />
                ) : (
            <div className="card">
              <div className="card-header text-white bg-dark">
                Relação de boletos por plataforma
              </div>
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-active">
                      <th scope="col">Plataforma</th>
                      <th scope="col" className="text-center">Quantidade</th>
                    </tr>
                  </thead>

                  <tbody style={{ fontSize: "14px" }}>
                    {invoices.map((invoice) => (
                      <tr key={invoice._id}>
                        <td>{invoice._id}</td>
                        <td className="text-center">{invoice.count}</td>
                      </tr>
                      
                    ))}
                  </tbody>
                  <tfoot>
                      <tr className="table-active">
                        <th>Total</th>
                        <th className="text-center">
                        {loadTotalTransfers()}
                        </th>
                      </tr>
                    </tfoot>
                </table>
              </div>
            </div>
                ) }
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
