import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import Loader from "../../components/Loader";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateEUAtoBR } from "../../components/DateEUAtoBR";
import { MoneyEUAtoBR } from "../../components/MoneyEUAtoBR";

export default function Transactions() {
  const [error, setError] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [payee, setPayee] = useState("");
  const [inicialBalance, setInicialBalance] = useState("");
  const [finalBalance, setFinalBalance] = useState("");  
  const [payees, setPayees] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadPayees = async () => {
    try {
      const headers = {
        action: "Payee:list",
      };
      const response = await api.get("/payees", {
        headers,
      });
      if (response.status === 200) {
        let arrayPayee = [];
        const listPayee = response.data.payee;
        listPayee.forEach((element) => {
          arrayPayee.push({
            number_doc: element.number_doc,
            name: element.name,
            platform: element.plataform,
            id: element._id,
          });
        });
        setPayees(arrayPayee);
      }
    } catch (err) {
      setError("Dados não encontrados");
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!year || year === "") {
        setLoading(false);
        setError("Campo ano é obrigatório");
        return;
    }

    if (!month || month === "") {
        setLoading(false);
        setError("Campo mês é obrigatório");
        return;
    }

    if (!payee || payee === "") {
        setLoading(false);
        setError("Campo beneficiário é obrigatório");
        return;
    }

    try {
      const headers = {
        action: "Transactions:view",
        payee: payee.replace(/"/g, '')
      };

      let body = { year, month}; 
      
      if (day.length > 0) {
        body = { year, month, day}; 
      } 

      await api.post(
        "/transactions",
        body,
        {
          headers,
        }
      ).then(function (response) {

        setError("");
        setLoading(false);
        setInicialBalance(response.data.initial_balance);
        setFinalBalance(response.data.final_balance);
        setTransactions(response.data.transactions);
        
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          setError(error.response.data.error);
        }
      });

    } catch (err) {
      setLoading(false);
      setError("Dados não encontrados");
    }
  }

  useEffect(() => {
    setLoading(true);
    loadPayees();
    setLoading(false);
  }, []);

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="col-md-12 col-lg-12 text-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
            </div>

            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Pesquisar transações por beneficiário
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                    <label className="form-label">
                      <b>Beneficiário:</b>
                    </label>
                    <Autocomplete
                      id="combo-box-demo"
                      options={payees}
                      getOptionLabel={(option) =>
                        `${option.number_doc} - ${option.platform} - ${option.name}`
                      }
                      style={{ fontSize: "12px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="CNPJ - Plataforma - Razão social"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, newValue) => {
                        setPayee(JSON.stringify(newValue.id, null, " "));
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                      <label className="form-label"><b>Ano Base:</b></label>
                      <input
                        type="text"
                        autoFocus="focus"
                        className="form-control form-control-lg"
                        placeholder="aaaa"
                        value={year}
                        onChange={(event) => setYear(event.target.value)}
                      />
                    </div>
                    <div className="col-md-1">
                      <label className="form-label"><b>Mês:</b></label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="mm"
                        value={month}
                        onChange={(event) => setMonth(event.target.value)}
                      />
                    </div>
                    <div className="col-md-1">
                      <label className="form-label"><b>Dia:</b></label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="dd"
                        value={day}
                        onChange={(event) => setDay(event.target.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "20%" }}
                      >
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card">
              <div className="card-header text-white bg-dark">
                Transações por beneficiário
              </div>
              <div className="card-body"> 

               { loading ? (
                  <Loader />
                ) : (

                <div className="row">
                  <table className="table table-bordered" style={{fontSize: "12px"}}>
                    <thead>
                        <tr>
                            <th className="text-end" colSpan="7">Saldo inicial: </th>
                            <th className="text-end" > { MoneyEUAtoBR(inicialBalance/100) }</th>
                        </tr>
                    </thead>
                  <thead>
                    <tr className="table-active">
                        <th className="text-left">Registro</th>
                        <th className="text-left">Ref.</th>
                        <th className="text-left">Descrição</th>
                        <th className="text-center">Tipo</th>
                        <th className="text-left">Identificador</th>
                        <th className="text-end">Valor</th>
                        <th className="text-center">Tipo de Transação</th>
                        <th className="text-end">Saldo</th>
                    </tr>
                  </thead>

                  <tbody>
                    {transactions.map((transaction) => (
                      <tr key={transaction.reference}>
                        <td>{DateEUAtoBR(transaction.entry_date)}</td>
                        <td>{transaction.type}</td>
                        <td>{transaction.description}</td>
                        <td className="text-center">{transaction.reference_type}</td>
                        <td>{transaction.reference}</td>
                        <td className="text-end">{MoneyEUAtoBR(transaction.amount/100)}</td>
                        <td className="text-center">{transaction.transaction_type}</td>
                        <td className="text-end">{MoneyEUAtoBR(transaction.balance/100)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                      <tr>
                          <td className="text-end" colSpan="7"><b>Saldo Final:</b></td>
                          <td className="text-end"><b>{ MoneyEUAtoBR(finalBalance/100) }</b></td>
                      </tr>
                  </tfoot>
                </table>
                </div>
                ) }
              </div>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
