import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { DateEUAtoBR } from "../../components/DateEUAtoBR";
import { MoneyEUAtoBR } from "../../components/MoneyEUAtoBR";
import Loader from "../../components/Loader";

export default function ShipmentWithdrawals() {

  const [listshipmentWithdrawals, setListshipmentWithdrawals] = useState([]);
  const [error, setError] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!searchDate || searchDate === "") {
      setLoading(false);
      setError("Campo Data de pesquisa é obrigatório");
      return;
    }

    try {
      const headers = {
        action: "Invoices:view",
      };
      const response = await api.post(
        "/request_bank_by_date_all",
        {
          currentDate: searchDate,
        },
        {
          headers,
        }
      );
      setLoading(false);
      setError("");
      setListshipmentWithdrawals(response.data.paymentsList);

    } catch (err) {
      setLoading(false);
      setError("Dados não encontrados");
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="col-md-12 col-lg-12 text-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
            </div>

            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Pesquisar remessas bancárias enviadas
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label">Data de pesquisa:</label>
                      <input
                        type="date"
                        autoFocus="focus"
                        className="form-control"
                        value={searchDate}
                        onChange={(event) => setSearchDate(event.target.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "20%" }}
                      >
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            { loading ? (
                  <Loader />
                ) : (
            <div className="card">
              <div className="card-header text-white bg-dark">
                Relação de remessas bancárias
              </div>
              <div className="card-body col-md-12 col-lg-12">

              {listshipmentWithdrawals.map((paymentsLists) => (

                  <div className="card col-md-12 col-lg-12" style={{fontSize: "12px"}}>
                    <div className="card-body">
                      <h5 className="card-title">{DateEUAtoBR(paymentsLists.created_at)}</h5>
                      <h6 className="card-subtitle mb-2 text-muted">Qtd: { paymentsLists.validPaymentsQuantity}</h6>
                      <h6 className="card-subtitle mb-2 text-muted">Total: { MoneyEUAtoBR(paymentsLists.validPaymentsTotalValue)}</h6>
                      
                      {paymentsLists.paymentsList.map((payments) => (

                          <ul className="list-group" style={{marginBottom: "10px"}}>
                            <li className="list-group-item active"><b>ID: </b> {payments.paymentId}</li>
                            <li className="list-group-item"><b>clearinghouseCode: </b>{payments.clearinghouseCode}</li>
                            <li className="list-group-item"><b>ispbCode: </b>{payments.ispbCode}</li>
                            <li className="list-group-item"><b>creditBranchOffice: </b>{payments.creditBranchOffice}</li>
                            <li className="list-group-item"><b>creditCurrentAccount: </b>{payments.creditCurrentAccount}</li>
                            <li className="list-group-item"><b>checkDigitsCreditCurrentAccount: </b>{payments.checkDigitsCreditCurrentAccount}</li>
                            <li className="list-group-item"><b>creditPaymentAccount: </b>{payments.creditPaymentAccount}</li>
                            <li className="list-group-item"><b>corporateTaxpayerRegistration: </b>{payments.corporateTaxpayerRegistration}</li>
                            <li className="list-group-item"><b>paymentDate: </b>{payments.paymentDate}</li>
                            <li className="list-group-item"><b>paymentValue: </b>{payments.paymentValue}</li>
                            <li className="list-group-item"><b>debitDocument: </b>{payments.debitDocument}</li>
                            <li className="list-group-item"><b>creditDocument: </b>{payments.creditDocument}</li>
                            <li className="list-group-item"><b>creditType: </b>{payments.creditType}</li>
                            <li className="list-group-item"><b>creditOrderPurposeCode: </b>{payments.creditOrderPurposeCode}</li>
                            <li className="list-group-item"><b>wireTransferPurposeCode: </b>{payments.wireTransferPurposeCode}</li>
                            <li className="list-group-item"><b>depositInCourtIdentifier: </b>{payments.depositInCourtIdentifier}</li>
                            <li className="list-group-item"><b>paymentDescription: </b>{payments.paymentDescription}</li>
                            <li className="list-group-item"><b>consistentPaymentIndicator: </b>{payments.consistentPaymentIndicator}</li>
                            
                              {payments.errorCodes.map((error) => (
                                <li className="list-group-item list-group-item-danger"><b>Error Code: {error}</b></li>
                              ))}
                          
                          </ul>

                      ))}

                    </div>
                  </div>


              ))}
              </div>
            </div>
                ) }
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
