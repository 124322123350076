import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { MoneyEUAtoBR } from "../../components/MoneyEUAtoBR";
import Loader from "../../components/Loader";

export default function RequestWithDraw({ history }) {
  const [currentBalance, setCurrentBalance] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [accountBank, setAccountBank] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadConvertMoney = (value) => {
    value = value.replace(/[.,()\s]/g, '');
    if (!isNaN(value)) {
      const amountReal = (value/100).toLocaleString('pt-br', {minimumFractionDigits: 2});
      setAmount(amountReal); 
    }
  }

  useEffect(() => {
    async function loadPlatformCurrentBalance() {
      setLoading(true);
      const headers = {
        action: "Balance:view",
      };
      const response = await api.get("/platform_balance", {
        headers,
      });
      setAccountBank(response.data.payeeData);
      setCurrentBalance((response.data.amount_balance / 100).toFixed(2));
      setLoading(false);
    }
    loadPlatformCurrentBalance();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!amount || amount < 1) {
      setLoading(false);
      setError("Campos valor é obrigatório");
      return null;
    } 

    if (amount > currentBalance - 1.7) {
      setLoading(false);
      setError("Valor da solicitação é maior que o saldo disponível");
      return null;
    }

    try {
      const headers = {
        action: "RequestWithDraw:create",
      };
      const response = await api.post(
        "/plataform_request_withdraw",
        {
          amount: parseInt(amount.replace(/[.,()\s]/g, '')),
        },
        {
          headers,
        }
      );

      if (response.data.transfer.status === "requested")
        setAmount("");
        setError("");
        setLoading(false);
        setAlert(
          "Solicitação aceite, aguarde um dia útil para o saldo ser depositado em sua conta bancária"
        );

      setTimeout(() => history.go(0), 3000);
    } catch (err) {
      setLoading(false);
      setError("Solicitação não atendida, entre em contato com adm do sistema");
    }
  }
  return (
    <>
      <NavBar />
      <div className="container">
        <div className="col-md-4 offset-md-4">
          <div className="col-md-12 col-lg-12 text-center">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {alert && (
              <div className="alert alert-success" role="alert">
                {alert}
              </div>
            )}
          </div>

          { loading ? (
                  <Loader />
                ) : (
                  
        <div className="card">

            <div className="card-header text-white bg-dark fs-4 text-center">
              Solicitação de saque
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <strong>Titular:</strong> {accountBank.holder_name}
              </li>
            </ul>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <strong>Banco:</strong> {accountBank.bank}
              </li>
            </ul>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <strong>Agência:</strong> {accountBank.bank_ag}
              </li>
            </ul>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <strong>Conta:</strong> {accountBank.bank_cc}
              </li>
            </ul>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <strong>Saldo atual:</strong> {MoneyEUAtoBR(currentBalance)}
              </li>
            </ul>
            <ul className="list-group list-group-flush">
              <li className="list-group-item list-group-item-success">
                <strong>Saldo disponível para saque:</strong>{" "}
                {MoneyEUAtoBR(currentBalance - 1.7)}
              </li>
            </ul>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">
                    <b>Valor:</b>
                  </label>
                  <input
                    name="amount"
                    type="text"
                    value={amount}
                    autoFocus="focus"
                    placeholder="Digite o valor"
                    className="form-control"
                    onChange={(event) => loadConvertMoney(event.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Solicitar Saque
                </button>
              </form>
            </div>
          </div>
          ) }
        </div>
      </div>
    </>
  );
}
