import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { MoneyEUAtoBR } from "../../components/MoneyEUAtoBR";
import { DateEUAtoBR } from "../../components/DateEUAtoBR";
import Loader from "../../components/Loader";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function RequestWithDrawByTaxesList() {
  const [error, setError] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [payee, setPayee] = useState("");
  const [payees, setPayees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transfers, setTransfers] = useState([]);

  let quantity_records = 0;

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!startDate || startDate === "") {
      setLoading(false);
      setError("Campo Data Inicial de pesquisa é obrigatório");
      return;
    }

    if (!endDate || endDate === "") {
        setLoading(false);
        setError("Campo Data Inicial de pesquisa é obrigatório");
        return;
    }

    if (!payee || payee === "") {
        setLoading(false);
        setError("Campo beneficiário é obrigatório");
        return;
    }

    try {
      const headers = {
        payee: payee.replace(/"/g, ''),
        action: "RequestWithDrawByTaxes:view",
      };
      const response = await api.post(
        "/transfer/payeeByPeriod",
        {
            startDate,
            endDate
        },
        {
          headers,
        }
      );
      setLoading(false);
      setError("");
      setTransfers(response.data);

    } catch (err) {
      setLoading(false);
      setError("Dados não encontrados");
    }
  }

  const loadTotalTransfers = () => {
    
    let totalQuant = 0;

    transfers.map((trans) => 
        totalQuant += trans.amount / 100
    )

    return MoneyEUAtoBR(totalQuant);
  }

  const loadPayees = async () => {
    try {
      const headers = {
        action: "Payee:list",
      };
      const response = await api.get("/payees", {
        headers,
      });
      if (response.status === 200) {
        let arrayPayee = [];
        const listPayee = response.data.payee;
        listPayee.forEach((element) => {
          arrayPayee.push({
            number_doc: element.number_doc,
            name: element.name,
            platform: element.plataform,
            id: element._id,
          });
        });
        setPayees(arrayPayee);
      }
    } catch (err) {
      setError("Dados não encontrados");
    }
  };

  useEffect(() => {
    setLoading(true);
    loadPayees();
    setLoading(false);
  }, []);

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="col-md-12 col-lg-12 text-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
            </div>

            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Pesquisar solicitação de saques por beneficiário e periodo
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                  <div className="col-md-6">
                    <label className="form-label">
                      <b>Beneficiário:</b>
                    </label>
                    <Autocomplete
                      id="combo-box-demo"
                      options={payees}
                      getOptionLabel={(option) =>
                        `${option.number_doc} - ${option.platform} - ${option.name}`
                      }
                      style={{ fontSize: "12px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="CNPJ - Plataforma - Razão social"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, newValue) => {
                        setPayee(JSON.stringify(newValue.id, null, " "));
                      }}
                    />
                  </div>
                    <div className="col-md-3">
                      <label className="form-label">Período:</label>
                      <input
                        type="date"
                        autoFocus="focus"
                        className="form-control form-control-lg"
                        value={startDate}
                        onChange={(event) => setStartDate(event.target.value)}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">Até:</label>
                      <input
                        type="date"
                        autoFocus="focus"
                        className="form-control form-control-lg"
                        value={endDate}
                        onChange={(event) => setEndDate(event.target.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "20%" }}
                      >
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            { loading ? (
                  <Loader />
                ) : (
            <div className="card">
              <div className="card-header text-white bg-dark">
                Relação de saques
              </div>
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr className="table-active">
                      <th scope="col">#</th>
                      <th scope="col">Solicitado</th>
                      <th className="text-center" scope="col">Dados Bancários</th>
                      <th className="text-center" scope="col">Situação</th>
                      <th className="text-center" scope="col">Atualizado</th>
                      <th className="text-right" scope="col">Valor</th>
                    </tr>
                  </thead>

                  <tbody style={{ fontSize: "14px" }}>
                    {transfers.map((transfer) => (
                      <tr key={transfer._id}>
                        <td>{++quantity_records}</td>
                        <td>{DateEUAtoBR(transfer.created_at)}</td>
                        <td>
                        {
                            
                            transfer.account_data ? (
                                 `Banco: ${transfer.account_data.bank} 
                                - Agência: ${transfer.account_data.bank_ag}`
                            ) : (
                                
                               <></>
                            )
                        
                        }<br></br>{
                            
                            transfer.account_data ? (
                                 `CC: ${transfer.account_data.bank_cc}
                                - Tipo: ${transfer.account_data.account_type}`
                            ) : (
                                
                               <></>
                            )
                        
                        }<br></br>
                        {
                            
                            transfer.account_data ? (
                                 `Titular: ${transfer.account_data.holder_name}
                                 - CPF/CNPJ: ${transfer.account_data.number_doc}`
                            ) : (
                                
                               <></>
                            )
                        
                        }
                        </td>
                        <td className="text-center">{transfer.status}</td>
                        <td className="text-center">{DateEUAtoBR(transfer.updated_at)}</td>
                        <td className="text-right">{MoneyEUAtoBR(transfer.amount/100)}</td>
                      </tr>
                      
                    ))}
                  </tbody>
                  <tfoot>
                      <tr className="table-active">
                        <th COLSPAN="5">Total</th>
                        <th>
                        {loadTotalTransfers()}
                        </th>
                      </tr>
                    </tfoot>
                </table>
              </div>
            </div>
                ) }
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
