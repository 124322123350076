import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { MoneyEUAtoBR } from "../../components/MoneyEUAtoBR";
import Loader from "../../components/Loader";

export default function RequestWithDrawByTaxes() {
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [transfers, setTransfers] = useState([]);

  let quantity_records = 0;

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!startDate || startDate === "") {
      setLoading(false);
      setError("Campo Data Inicial de pesquisa é obrigatório");
      return;
    }

    if (!endDate || endDate === "") {
      setLoading(false);
      setError("Campo Data Final de pesquisa é obrigatório");
      return;
    }

    try {
      const headers = {
        action: "RequestWithDrawByTaxes:view",
      };
      const response = await api.post(
        "/transfer/taxes",
        { startDate, endDate },
        { headers }
      );
      setLoading(false);
      setError("");

      const sortedArray = response.data.sort((a, b) => {
        if (a.type_transfer < b.type_transfer) return -1;
        if (a.type_transfer > b.type_transfer) return 1;
        return 0;
      });

      setTransfers(sortedArray);
    } catch (err) {
      setLoading(false);
      setError("Dados não encontrados");
    }
  }

  let previousType = ""; // Variável para acompanhar o type_transfer anterior
  let totalQtd = 0; // Variáveis para acompanhar os totais
  let totalGeral = 0;
  let totalTodasTaxas = 0;
  let qtdGeralTaxas = 0;

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="col-md-12 col-lg-12 text-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
            </div>

            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Pesquisar taxas bancárias por data
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label">Período:</label>
                      <input
                        type="date"
                        autoFocus="focus"
                        className="form-control"
                        value={startDate}
                        onChange={(event) => setStartDate(event.target.value)}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">Até:</label>
                      <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(event) => setEndDate(event.target.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "20%" }}
                      >
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <div className="card">
                <div className="card-header text-white bg-dark">
                  Relação de taxas
                </div>
                <div className="card-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="table-active">
                        <th scope="col">#</th>
                        <th scope="col">Banco</th>
                        <th className="text-center" scope="col">Tipo</th>
                        <th className="text-center" scope="col">Quantidade</th>
                        <th className="text-right" scope="col">Taxa</th>
                        <th className="text-right" scope="col">Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      {transfers.map((transfer, index) => {
                        // Calcula os totais

                        let currentRow = (
                          <tr key={index}>
                            <td>{++quantity_records}</td>
                            <td>{transfer.account_data.bank}</td>
                            <td className="text-center">
                              {transfer.type_transfer}
                            </td>
                            <td className="text-center">{transfer.qtd}</td>
                            <td className="text-right">
                              {MoneyEUAtoBR(
                                ((transfer.taxes - transfer.profit) / 100) /
                                  transfer.qtd
                              )}
                            </td>
                            <td className="text-right">
                              {MoneyEUAtoBR((transfer.taxes - transfer.profit) / 100)}
                            </td>
                          </tr>
                        );

                        if (previousType && previousType !== transfer.type_transfer) {
                          // Linha de total por tipo de transferência
                          const totalRow = (
                            <tr key={`total-${previousType}`} className="table-active">
                              <td colSpan="3" className="text-right">
                                <strong>Total {previousType}</strong>
                              </td>
                              <td className="text-center">
                                <strong>{totalQtd}</strong>
                              </td>
                              <td className="text-right"></td>
                              <td className="text-right">
                                <strong>{MoneyEUAtoBR(totalGeral)}</strong>
                              </td>
                            </tr>
                          );

                          // Reinicia os totais para o novo tipo
                          totalQtd = transfer.qtd;
                          totalGeral = (transfer.taxes - transfer.profit) / 100;

                          previousType = transfer.type_transfer;
                          return [totalRow, currentRow];
                        }

                        totalQtd += transfer.qtd;
                        totalGeral += (transfer.taxes - transfer.profit) / 100;
                        totalTodasTaxas += (transfer.taxes - transfer.profit) / 100;
                        qtdGeralTaxas += transfer.qtd;

                        previousType = transfer.type_transfer;
                        return currentRow;
                      })}
                    </tbody>

                    <tfoot>
                      <tr className="table-active">
                        <th colSpan="3">Total TED</th>
                        <th className="text-center">{totalQtd}</th>
                        <th></th>
                        <th className="text-right">{MoneyEUAtoBR(totalGeral)}</th>
                      </tr>
                      <tr className="table-active">
                        <th colSpan="3">Total Geral</th>
                        <th className="text-center">{qtdGeralTaxas}</th>
                        <th></th>
                        <th className="text-right">{MoneyEUAtoBR(totalTodasTaxas)}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
