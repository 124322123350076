import { NavLink } from "react-router-dom";

export const Columns = () => {
  return [
    {
        name: "plataform",
        label: "Plataforma",
        options: {
          filter: true,
          sort: true,
        },
    },
    {
      name: "name",
      label: "Beneficiário",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "number_doc",
      label: "CNPJ/CPF",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "city",
      label: "Cidade",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "state",
      label: "UF",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "_id",
      label: "Ação",
      options: {
        filter: true,
        sort: true,
        align: 'center',
        customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <NavLink
                className="btn btn-primary"
                to={"/payee/edit/" + value}
              >
                Editar
              </NavLink>
            );
          }
      },
    },
  ];
};
