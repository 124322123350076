import React, { useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { MoneyEUAtoBR } from "../../components/MoneyEUAtoBR";
import Loader from "../../components/Loader";

export default function SearchBatchInformation() {
  const [error, setError] = useState("");
  const [numberBatch, setNumberBatch] = useState("");
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([], true);
  let quantity_records = 0;

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (!numberBatch || numberBatch === "") {
      setLoading(false);
      setError("Campo Número de lote de pesquisa é obrigatório");
      return;
    }

    try {
      const headers = {
        action: "Invoices:view",
      };
      const response = await api.post(
        "/request_bank_by_id",
        {
          requestIdentification: numberBatch,
        },
        {
          headers,
        }
      );
      setLoading(false);
      setError("");
      setPayments(response.data.paymentsList.pagamentos);
    } catch (err) {
      setLoading(false);
      setError("Dados não encontrados");
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="col-md-12 col-lg-12 text-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
            </div>

            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Buscar informações de um lote
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label">Número:</label>
                      <input
                        type="text"
                        autoFocus="focus"
                        className="form-control"
                        value={numberBatch}
                        onChange={(event) => setNumberBatch(event.target.value)}
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ marginTop: "17%" }}
                      >
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            { loading ? (
                  <Loader />
                ) : (
            <div className="card">
              <div className="card-header text-white bg-dark">
                Informações do lote
              </div>
              <div className="card-body">

                <table className="table table-bordered">
                  <thead>
                    <tr className="table-active">
                      <th scope="col">#</th>
                      <th scope="col">Pago em</th>
                      <th scope="col">ID</th>
                      <th scope="col">Beneficiário</th>
                      <th scope="col">Documento</th>
                      <th scope="col">Valor</th>   
                    </tr>
                  </thead>

                  <tbody style={{ fontSize: "14px" }}>
                    {payments.map((payment) => (
                      <tr key={payment.identificadorPagamento}>
                        <td>{++quantity_records}</td>
                        <td>{payment.dataPagamento}</td>
                        <td>{payment.identificadorPagamento}</td>
                        <td>{payment.nomeBeneficiario}</td>
                        <td>{payment.cpfCnpjBeneficiario}</td>
                        <td>{MoneyEUAtoBR(payment.valorPagamento/100)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
                ) }
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
