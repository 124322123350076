import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import { getPublicKey } from "../../services/auth";
import { NavLink } from "react-router-dom";
import Loader from "../../components/Loader";
import { EditValidate } from "./editValidade";

export default function Edit({ match, history }) {
  const { id } = match.params;

  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(true);
  const [banksPattern, setBanksPattern] = useState([]);
  const [bPattern, setBPattern] = useState(false);
  const [holderName, setHolderName] = useState("");
  const [typeDoc, setTypeDoc] = useState("");
  const [numberDoc, setNumberDoc] = useState("");
  const [bank, setBank] = useState("");
  const [bankNumber, setBankNumber] = useState("");
  const [bankAg, setBankAg] = useState("");
  const [bankCc, setBankCc] = useState("");
  const [bankAgPattern, setBankAgPattern] = useState("");
  const [bankCcPattern, setBankCcPattern] = useState("");
  const [accountType, setAccountType] = useState("");
  const [transferType, setTransferType] = useState("");

  const typeDocs = [
    {"id": 1, "description": "Pessoa Física"},
    {"id": 2, "description": "Pessoa Jurídica"}
  ];

  const typeTransf = [
    {"id": 'PIX', "description": "PIX"},
    {"id": 'TED', "description": "TED"}
  ];

  const typeCC = [
    {"id": 1, "description": "Corrente"},
    {"id": 2, "description": "Poupança"}
  ];

  useEffect(() => {
    async function loadBankData() {
      try {
        const headers = {
          action: "AccountBank:view",
        };
        const response = await api.get("/bank_account/" + id, {
          headers,
        });

        setHolderName(response.data.bankAccount.holder_name);
        setTypeDoc(response.data.bankAccount.type_doc);
        setNumberDoc(response.data.bankAccount.number_doc);
        setBank(response.data.bankAccount.bank);
        setBankNumber(response.data.bankAccount.bank_number);
        setAccountType(response.data.bankAccount.account_type);
        setBankAg(response.data.bankAccount.bank_ag);
        setBankCc(response.data.bankAccount.bank_cc);
        setTransferType(response.data.bankAccount.transfer_type);

      } catch (err) {
        setError("Dados não encontrados");
      }

      try {
        const headers = {
          action: "AccountBank:view",
        };
        const response = await api.get("/banks_pattern", {
          headers,
        });
        setBanksPattern(response.data.banksPattern);
      } catch (err) {
        setError("Dados não encontrados");
      }
      setLoading(false);
    }
    loadBankData();
  },[id]);

  const viewBankPattern = (number_bank) => {
    let pattern = banksPattern.find((o) => o.number === number_bank);
    setBank(pattern.name);
    setBankNumber(number_bank);
    setBankAgPattern(pattern.agency);
    setBankCcPattern(pattern.account);
    setBPattern(true);
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    const bodyObject = {
      holder_name: holderName,
      type_doc: typeDoc,
      number_doc: numberDoc,
      bank: bank,
      bank_number: bankNumber,
      bank_ag: bankAg,
      bank_cc: bankCc,
      account_type: accountType,
      user_id: getPublicKey(),
      transfer_type: transferType
    };

    const isValid = await EditValidate(bodyObject);
    if (isValid !== true) {
      setError(isValid);
      setLoading(false);
      return false;
    }

    try {
      const headers = {
        payee: id,
        action: "AccountBank:edit",
      };
      const response = await api.put("/bank_account", bodyObject, {
        headers,
      });

      if (response.status === 200) {

        setError("");
        setLoading(false);
        setAlert("Dados Atualizados com sucesso");
      }
    } catch (err) {
      setLoading(false);
      setError("Dados inválidos");
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="col-md-6 offset-md-3">
          <div className="col-md-12 col-lg-12 text-center">
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

            {alert && (
              <div className="alert alert-success" role="alert">
                {alert}
              </div>
            )}
          </div>

          <div className="card">
            <div className="card-header text-white bg-dark fs-4 text-center">
              Editar conta bancária
            </div>

            {loading ? (
              <Loader />
            ) : (
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Titular:</b>
                    </label>
                    <input
                      name="holderName"
                      type="text"
                      className="form-control"
                      value={holderName}
                      onChange={(event) => setHolderName(event.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      <b>Tipo:</b>
                    </label>
                    <select
                      name="typeDoc"
                      onChange={(event) => setTypeDoc(event.target.value)}
                      className="form-select"
                      value={typeDoc}
                    >
                      {typeDocs.map((type) => (
                        <option value={type.id}>{type.description}</option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      <b>Tipo de transferência:</b>
                    </label>
                    <select
                      name="transferType"
                      onChange={(event) => setTransferType(event.target.value)}
                      className="form-select"
                      value={transferType}
                    >
                      {typeTransf.map((type) => (
                        <option value={type.id}>{type.description}</option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      <b>Documento:</b>
                    </label>
                    <input
                      name="numberDoc"
                      type="text"
                      className="form-control"
                      value={numberDoc}
                      onChange={(event) => setNumberDoc(event.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      <b>Banco:</b>
                    </label>
                    <select
                      name="bankNumber"
                      onChange={(event) => viewBankPattern(event.target.value)}
                      className="form-select"
                      value={bankNumber}
                    >
                      {banksPattern.map((bankp) => (
                        <option value={bankp.number}>{bankp.name}</option>
                      ))}
                    </select>
                    <input
                      type="hidden"
                      name="bank"
                      value={bank}
                      onChange={(event) => setBank(event.target.value)}
                    />
                  </div>

                  {bPattern ? (
                    <div className="mb-3">
                      <div className="card bg-primary mb-3">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item active">
                            <b>Padrão de dados bancários</b>
                          </li>
                          <li className="list-group-item">
                            Agência: {bankAgPattern}
                          </li>
                          <li className="list-group-item">
                            Conta: {bankCcPattern}
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="mb-3">
                    <label className="form-label">
                      <b>Tipo de conta:</b>
                    </label>
                    <select
                      name="accountType"
                      onChange={(event) => setAccountType(event.target.value)}
                      className="form-select"
                      value={{accountType}}
                    >
                      {typeCC.map((type) => (
                        <option value={type.description}>{type.description}</option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      <b>Agência:</b>
                    </label>
                    <input
                      name="bankAg"
                      type="text"
                      className="form-control"
                      value={bankAg}
                      onChange={(event) => setBankAg(event.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">
                      <b>Número da conta:</b>
                    </label>
                    <input
                      name="bankCc"
                      type="text"
                      className="form-control"
                      value={bankCc}
                      onChange={(event) => setBankCc(event.target.value)}
                    />
                  </div>

                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary btn-lg">
                      Atualizar
                    </button>
                    <NavLink
                      className="btn btn-warning btn-lg"
                      to={"/bank_accounts"}
                    >
                      Voltar
                    </NavLink>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
