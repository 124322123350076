import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import api from "../../services/Api";
import Loader from "../../components/Loader";

export default function PlatformTrigger() {
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");
  const [paidDate, setPaidDate] = useState("");
  const [platform, setPlatform] = useState("");
  const [platforms, setPlatforms] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadPlatforms() {
      const headers = {
        action: "PlatformTriggers:view",
      };

      const response = await api.get("/platforms", {
        headers,
      });

      setPlatforms(response.data.platforms);
      setLoading(false);
    }

    loadPlatforms();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (paidDate === null || paidDate === "") {
      setLoading(false);
      setError("Data de pagamento é obrigatória");
      return;
    }

    if (platform === null || platform === "") {
      setLoading(false);
      setError("Campo plataforma é obrigatório");
      return;
    }

    try {
      const headers = {
        action: "RequestBankTransfer:view",
      };
      const response = await api.post(
        "/webhook_platform",
        {
          paid_date: paidDate,
          platform,
        },
        {
          headers,
        }
      );

      setError("");
      setAlert(response.data + " enviados(s)");
      setLoading(false);
    } catch (err) {
      setAlert("");
      setLoading(false);
      setError("Nenhum pagamento encontrada");
    }
  }

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            {loading ? (
              <Loader />
            ) : (
              <div className="col-md-12 col-lg-12 text-center">
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}

                {alert && (
                  <div className="alert alert-success" role="alert">
                    {alert}
                  </div>
                )}
              </div>
            )}

            <div className="card" style={{ marginBottom: "1%" }}>
              <div className="card-header text-white bg-dark">
                Reenviar gatilho por plataforma
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <label className="form-label">
                      <b>Data de pagamento:</b>
                    </label>
                    <input
                      type="date"
                      autoFocus="focus"
                      className="form-control"
                      value={paidDate}
                      onChange={(event) => setPaidDate(event.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">
                      <b>Plataforma:</b>
                    </label>
                    <select
                      name="platform"
                      onChange={(event) => setPlatform(event.target.value)}
                      className="form-select"
                      value={platform}
                    >
                      <option value="">-- Selecione --</option>
                      {platforms.map((platform, index) => (
                        <option key={index} value={platform._id}>
                          {platform._id}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="d-grid gap-2">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{ marginTop: "5%" }}
                    >
                      Carregar
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
